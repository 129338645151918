import React, { useEffect, useState } from 'react';
import { apiUrl } from '../../../config'
import { fetchData, formatDate } from '../../../utils/api';
import { boolElemByRole, set_status } from '../../../utils/dynamic';
import { useLanguage } from '../../../LanguageContext';


const BookingView = ({ booking, onClose }) => {
    const { language } = useLanguage();
    const [rooms, setRooms] = useState([])
    const [selectedPrice, setSelectedPrice] = useState('')
    const [messageStatusBook, setMessageStatusBook] = useState('')
    const [viewBooking] = useState({
        ...booking,
        available_from: formatDate(booking.available_from),
        available_to: formatDate(booking.available_to)
    });

    async function getRooms() {
        try {
            const token = localStorage.getItem('accessToken');
            const rooms = await fetchData(`${apiUrl}/api/sys/get-rooms-by-season`, 'GET', token)
            if (rooms.request.status === 0) {
                setRooms(rooms.request.data)
            }
        } catch (error) {
            console.error("Не удалось получить данные о номерах:", error);
        }
    }
    const handleSendMail = async (bookingId) => {
        try {
            const token = localStorage.getItem('accessToken');
            const res = await fetchData(`${apiUrl}/api/sys/send-mail-booktu?id=${bookingId}`, "GET", token)
            setMessageStatusBook(res.request.message)
        } catch (error) {
            console.error('Ошибка:', error)
        }
    }
    useEffect(() => {
        getRooms()
    }, [])
    useEffect(() => {
        // Проверяем, есть ли данные rooms и установлен ли room_id
        if (rooms && rooms.length > 0 && viewBooking.room_id) {
            const selectedRoom = rooms.find((room) => room.id === parseInt(viewBooking.room_id));
            setSelectedPrice(selectedRoom ? selectedRoom.price : '');
        }
    }, [rooms, viewBooking.room_id])
    const set_statusComp = async (bookingId, method) => {
        const res = await set_status(bookingId, method)
        if (res) {
            setMessageStatusBook(res)
        }
    }
    return (
        <div className='system-background-hide'>
            <section className="system-container system-container-form">
                <div className='system-container__inner'>
                    <header className="system-header">Просмотр</header>
                    <div className="system-form">
                        <div className="system-input-box">
                            <label>Номер брони</label>
                            <input disabled placeholder="Номер брони" value={viewBooking.book_number} type="text" name='book_number' />
                        </div>
  
                        <div className="system-input-box">
                            <label>Серийный номер паспорта</label>
                            <input disabled placeholder="Серия паспорта" value={viewBooking.passport_serial_number} type="text" name='passport_serial_number' />
                        </div>

                        <div className="system-input-box">
                            <label>Полное имя гостя</label>
                            <input disabled placeholder="Имя/Фамилия" value={viewBooking.full_name} type="text" name='full_name' />
                        </div>

                        <div>
                            {viewBooking.guests && viewBooking.guests.map((guest, index) => (
                            <div key={index}>
                                <div className="system-input-box">
                                    <label>Серийный номер паспорта</label>
                                    <input disabled placeholder="Не обязательно" value={guest.passport_serial_number || ""} type="text" />
                                </div>

                                <div className="system-input-box">
                                    <label>Полное имя гостя</label>
                                    <input disabled placeholder="Не обязательно" value={guest.full_name || ""} type="text" />
                                </div>
                            </div>
                            ))}

                        </div>



                        <div className="system-input-box">
                            <label>Полное имя плательщика</label>
                            <input disabled placeholder="" value={viewBooking.cardholdername  || ''} type="text" name='cardholdername' />
                        </div>
                        {boolElemByRole(['admin', 'booker']) ? (
                        <div className="system-input-box">
                            <label>4 последние цифры банковской карты</label>
                            <input disabled placeholder="" value={viewBooking.card_number || ''} type="text" name='card_number' />
                        </div>
                        ):(null)}

                        <div className="system-input-box">
                            <label>Телефон</label>
                            <input disabled placeholder="" value={viewBooking.phone_number || ''} type="telephone" name="phone_number" />
                        </div>
                        <div className="system-input-box">
                            <label>Почта</label>
                            <input disabled placeholder="" value={viewBooking.email} type="telephone" name="email" />
                        </div>
                        <div className="system-column">
                            <div className="system-input-box">
                                <label>Начало брони</label>
                                <input disabled value={viewBooking.available_from} placeholder="Заезд" type="date" name='available_from' />
                            </div>
                            <div className="system-input-box">
                                <label>Окончание брони</label>
                                <input disabled value={viewBooking.available_to} placeholder="Выезд" type="date" name='available_to' />
                            </div>
                        </div>
                        <div className="system-input-box system-address">
                            <label>Тип номера</label>
                            <div className="system-column">
                                <div className="system-select-box">
                                    <select disabled value={viewBooking.room_id || ''} name='room_id'>
                                        {rooms && rooms.length > 0 ? (
                                            rooms.map((room) => (
                                                <option key={room.id} value={room.id}>{room.translations.room_type[language]}</option>
                                            ))
                                        ) : (
                                            <option value="">Нет номеров</option>
                                        )}
                                    </select>
                                </div>
                                <input disabled placeholder={selectedPrice || "Цена не доступна"} type="text" />
                            </div>
                        </div>
                        {messageStatusBook}
                        <div className='flex-btns'>
                            {boolElemByRole(['admin', 'booker']) && (
                                (booking.status === 0 || booking.status === 1) && (
                                    <button className="system-booking-table-btn system-booking-table-btn-view" onClick={() => handleSendMail(viewBooking.id)}>Отправить данные пользователю</button>
                                )
                            )}
                            {boolElemByRole(['admin', 'reception']) ? (
                                viewBooking.reception_status === 1 ? (
                                    <button type='button' className="system-booking-table-btn system-booking-table-btn-view" onClick={() => set_statusComp(viewBooking.id, 'acceptReceptionProgram')}>Подтвердить бронь</button>
                                ) : (null) ||
                                viewBooking.reception_status === 0 ? (
                                    <button type='button' className="system-booking-table-btn system-booking-table-btn-cancel" onClick={() => set_statusComp(viewBooking.id, 'cancelReceptionProgram')}>Отменить подтверждение</button>
                                ) : (null)
                            ) : (null)}
                            <button type='button' className="system-booking-table-btn system-booking-table-btn-cancel" onClick={onClose}>Закрыть</button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default BookingView;
import React from 'react';

function Header() {

  // const userRole = localStorage.getItem('asd'); // Получаем роль из localStorage

  return (
    <header className='system-header'>
      <h1>Дашборд</h1>
      <div className="user-info">
        {/* <p>Пользователь: </p> */}
        {/* <a href="#">Выход</a> */}
      </div>
    </header>
  );
}

export default Header;

// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { LanguageProvider } from './LanguageContext';
import MaintenanceMode from './components/MaintenanceMode';
import System from './templates/System';
import PageNotFound from './templates/PageNotFound';
import Login from './components/system/Login';
import Protected from './components/system/Protected';


import Home from './templates/Home';
import AboutUs from './templates/AboutUs';
import Rooms from './templates/Rooms';
import RoomDetail from './templates/RoomDetail';
import Services from './templates/Services';
import Gallery from './templates/Gallery';
import Contacts from './templates/Contacts';
import ClientRegisterComponent from './components/authorization/ClientRegisterComponent';
import ClientBooksComponent from './components/client/ClientBooksComponent';
import ClientBookCancelComponent from './components/client/ClientBookCancelComponent';
import BookRooms from './templates/BookRooms';

const AppContent = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      document.body.className = 'home-background';
    } else if (location.pathname === '/system') {
      document.body.className = 'system-background';
    }
  }, [location]);

  return (
    <LanguageProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contacts" element={<Contacts />} />

        <Route path="/rooms" element={<Rooms />} />
        <Route path="/rooms/:id/:typePage" element={<RoomDetail />} />

        <Route
          path="/book-rooms"
          element={<Protected element={<BookRooms />} requiredRoles={['client']} />}
        />
        <Route path="/rooms/:id/:typePage" element={<RoomDetail />} />

        <Route path="/authorization" element={<ClientRegisterComponent />} />
        <Route path="/books" element={<Protected element={<ClientBooksComponent />} requiredRoles={['client']} />} />
        <Route path="/books/cancel/:id" element={<Protected element={<ClientBookCancelComponent />} requiredRoles={['client']} />} />

        <Route path="/login" element={<Login />} />
        {/* Reception bookings */}
        <Route path="/system/reception" element={<Protected element={<System type="reception" />} requiredRoles={['admin', 'reception']} />} />
        <Route path="/system/reception/confirmes" element={<Protected element={<System type="receptionConfirmes" />} requiredRoles={['admin', 'reception']} />} />
        <Route path="/system/reception/canceled-bookings" element={<Protected element={<System type="receptionCanceledBookings" />} requiredRoles={['admin', 'reception']} />} />
        {/* Offline Active bookings */}
        <Route path="/system/off" element={<Protected element={<System type="offMain" />} requiredRoles={['admin', 'booker']} />} />
        <Route path="/system/off/expired-bookings" element={<Protected element={<System type="offExpiredBookings" />} requiredRoles={['admin', 'booker']} />} />
        <Route path="/system/off/canceled-bookings" element={<Protected element={<System type="offCanceledBookings" />} requiredRoles={['admin', 'booker', 'reception']} />} />
        {/* Active bookings */}
        <Route path="/system" element={<Protected element={<System type="main" />} requiredRoles={['admin', 'booker']} />} />
        {/* ecBookings bookings */}
        <Route path="/system/expired-bookings" element={<Protected element={<System type="expiredBookings" />} requiredRoles={['admin', 'booker']} />} />
        <Route path="/system/canceled-bookings" element={<Protected element={<System type="canceledBookings" />} requiredRoles={['admin', 'booker']} />} />
        <Route path="/system/rooms" element={<Protected element={<System type="rooms" />} requiredRoles={['admin', 'booker']} />} />
        <Route path="/system/users" element={<Protected element={<System type="users" />} requiredRoles={['admin']} />} />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </LanguageProvider>

  );
};

const App = () => {
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsUnderMaintenance(false), 5000);
    return () => clearTimeout(timer); // Clear timer on unmount
  }, []);

  return (
    <React.Fragment>
      {isUnderMaintenance ? (
        <MaintenanceMode />
      ) : (
        <Router>
          <AppContent />
        </Router>
      )}
    </React.Fragment>
  );
};

export default App;

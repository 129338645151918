import React from 'react';

function FooterComponent() {
  const savedLanguage = localStorage.getItem('language') || 'tm';

  const langs = {
    'ru': {
      'address': "Туркменистан, Национальная туристическая зона «Аваза»",
      'tel': "Тел",
      'faks': "Факс",
    },
    'tm': {
      'address': "Türkmenistan, “Awaza” milli syýahatçylyk zolagy",
      'tel': "Tel",
      'faks': "Faks",
    },
    'en': {
      'address': "Turkmenistan, National Tourist Zone “Avaza”",
      'tel': "Tel",
      'faks': "Fax",
    }
  };
  return (
    <footer>
      <div className="container footer-flex">
        <section className="copyright">
          <a href="/" className="footer-logo">
            <img
              src={'/media/img/icons/logo.png'}
              alt="logo"
              className="footer-logo-img"
            />
          </a>
        </section>
        <section className="footer-info-block">
          <ul className="footer-info-block_ul">
            <li className="footer-info-block_ul_li">
            {langs[savedLanguage].address}
            </li>
            <li className="footer-info-block_ul_li footer-info-block_ul_li-block">
              <a href="tel:+99324357654">{langs[savedLanguage].tel}: (+993 243) 5-76-54</a>
              <a href="tel:+99324357635">{langs[savedLanguage].tel}: (+993 243) 5-76-35</a>
              <a href="tel:+99324357630">{langs[savedLanguage].faks}: (+993 243) 5-76-30</a>
            </li>
          </ul>
        </section>
      </div>
      <p className="copyright-text">{new Date().getFullYear()}</p>
    </footer>
  );
}

export default FooterComponent;

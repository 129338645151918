import React, { useState } from 'react';
import { apiUrl } from '../../../config';
import { useLanguage } from '../../../LanguageContext';
import LanguageChanger from '../../../utils/modules/LanguageChanger';
const RoomView = ({ room, onClose }) => {
    const [viewRoom] = useState(room);
    const { language } = useLanguage();

    return (
        <div className='system-background-hide'>
            <section className="system-container system-container-form">
                <div className='system-container__inner'>
                    <header className="system-header">Просмотр</header>
                    <div className="system-form">
                        <LanguageChanger />
                        <div className="system-input-box">
                            <label>Тип номера</label>
                            <input disabled placeholder="" value={viewRoom.translations.room_type[language] ? viewRoom.translations.room_type[language] : ''} type="text" name='room_type' />
                        </div>
                        <div className="system-input-box">
                            <label>Количество номеров</label>
                            <input disabled placeholder="Число" value={viewRoom.count} type="number" name='count' />
                        </div>
                        <div className="system-input-box">
                            <label>Описание</label>
                            <textarea disabled placeholder="" value={viewRoom.translations.description[language] ? viewRoom.translations.description[language] : ''} type="text" name='description'></textarea>
                        </div>
                        <div className="system-input-box">
                            <label>Дополнительная информация</label>
                            <textarea disabled placeholder="" value={viewRoom.translations.additional_info[language] ? viewRoom.translations.additional_info[language] : ''} type="text" name='additional_info'></textarea>
                        </div>
                        <div className="system-input-box">
                            <label>Цена, летний сезон</label>
                            <input disabled placeholder="Число" value={viewRoom.price_summer} type="number" name='price_summer' />
                        </div>
                        <div className="system-input-box">
                            <label>Цена, зимний сезон</label>
                            <input disabled placeholder="Число" value={viewRoom.price_winter} type="number" name='price_winter' />
                        </div>
                        <div className="system-input-box">
                            <label>Максимальное количество гостей, которые могут разместиться в номере</label>
                            <input disabled placeholder="Число" value={viewRoom.capacity} type="number" name='capacity' />
                        </div>
                        <div className="system-input-box">
                            <label>Количество комнат в номере</label>
                            <input disabled placeholder="Число" value={viewRoom.rooms_count} type="number" name='rooms_count' />
                        </div>
                        {/* <div className="system-input-box">
                            <label>Услуги</label>
                            {viewRoom.translations.amenities[language] ? (
                                viewRoom.translations.amenities[language].map((amenity, index) => (
                                    <div key={index} className="amenity-item">
                                        <input
                                            disabled
                                            placeholder="Удобство"
                                            value={amenity}
                                            type="text"
                                            />
                                    </div>
                                ))
                            ) : null}
                        </div> */}
                        <div className='flex-btns'>
                            <button type='button' className="system-booking-table-btn system-booking-table-btn-cancel" onClick={onClose}>Закрыть</button>
                        </div>
                    </div>
                </div>
                {viewRoom.images.length > 0 && (
                    <div className="system-container__inner system-container__inner-images">
                        <div className="system-container__inner__images">
                            <header className="system-header">Фотографии</header>
                            {viewRoom.images.map((image, index) => (
                                <div className='system-header__view-image' key={index}>
                                    <img 
                                        src={apiUrl + `/api/images/${image}`}
                                        alt={`Room ${index}`}
                                    />
                                </div>
                            ))}
                            </div>
                    </div>
                )}
            </section>
        </div>
    );
}

export default RoomView;
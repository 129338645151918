import React, { useState } from 'react';
import { apiUrl } from '../../../config'
import { fetchData } from '../../../utils/api';


const UserNew = ({ rolesData, onClose }) => {
    const [messageNewData, setMessageNewData] = useState([])
    const [roles] = useState(rolesData);
    const [newUser, setNewUser] = useState({
        'role_id': '',
        'username': '',
        'password': ''
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser((prevUser) => ({ ...prevUser, [name]: value }));
    };

    const sendData = async (dataNew) => {
        const token = localStorage.getItem('accessToken');
        const data = await fetchData(`${apiUrl}/api/sys/new-user`, "POST", token, {body: JSON.stringify(dataNew)})
        setMessageNewData(data.request.message)
    }

    return (
        <div className='system-background-hide'>
            <section className="system-container">
                <div className='system-container__inner'>
                    <header className="system-header">Редактирование</header>
                    <form className="system-form">
                        <div className="system-input-box">
                            <label>Login</label>
                            <input required placeholder="Имя пользователя" value={newUser.username} type="text" name='username' onChange={handleInputChange} />
                        </div>
                        <div className="system-input-box">
                            <label>Пароль</label>
                            <input required placeholder="Password" value={newUser.password} type="password" name="password" onChange={handleInputChange} />
                        </div>
                        <div className="system-input-box system-address">
                            <label>Роль</label>
                            <div className="system-column">
                                <div className="system-select-box">
                                    <select required value={newUser.role_id} onChange={handleInputChange} name='role_id'>
                                        {roles && roles.length > 0 ? (
                                            roles.map((role) => (
                                                <option key={role.id} value={role.id}>{role.role_name}</option>
                                            ))
                                        ) : (
                                            <option value="">Нет ролей</option>
                                        )}

                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='flex-btns'>
                            <button type='button' className="system-booking-table-btn system-booking-table-btn-cancel" onClick={onClose}>Закрыть</button>
                            <button type='button' className="system-booking-table-btn system-booking-table-btn-view" onClick={() => sendData(newUser)}>Сохранить</button>
                        </div>
                        {Array.isArray(messageNewData) && messageNewData.length > 0 ? (
                            messageNewData.map((mess, index) => (
                                <span className='error-message' key={index + 1}>{mess}</span>
                            ))
                        ) : (null)}
                    </form>
                </div>
            </section>
        </div>
    );
}

export default UserNew;
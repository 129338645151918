import React, { useEffect, useState } from 'react';
import { apiUrl } from '../../../config'
import { fetchData, formatDate } from '../../../utils/api';
import { boolElemByRole } from '../../../utils/dynamic';
import { useLanguage } from '../../../LanguageContext';


const BookingEdit = ({ booking, onClose }) => {
    const { language } = useLanguage();
    const [messageEditData, setMessageEditData] = useState([])
    const [rooms, setRooms] = useState([])
    const [selectedPrice, setSelectedPrice] = useState('')
    const [editBooking, setEditBooking] = useState({
        ...booking,
        available_from: formatDate(booking.available_from),
        available_to: formatDate(booking.available_to)
    });

    async function getRooms() {
        try {
            const token = localStorage.getItem('accessToken');
            const rooms = await fetchData(`${apiUrl}/api/sys/get-rooms-by-season`, 'GET', token)            
            if (rooms.request.status === 0) {
                setRooms(rooms.request.data)
            }
        } catch (error) {
            console.error("Не удалось получить данные о номерах:", error);
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditBooking((prevBooking) => ({ ...prevBooking, [name]: value }));

        if (name === 'room_id') {
            // Находим цену для выбранной комнаты и обновляем состояние
            const selectedRoom = rooms.find((room) => room.id === parseInt(value));
            setSelectedPrice(selectedRoom ? selectedRoom.price : ''); // Просто для отрисовки на странице
        }
    };

    useEffect(() => {
        getRooms()
    }, [])
    useEffect(() => {
        // Проверяем, есть ли данные rooms и установлен ли room_id
        if (rooms && rooms.length > 0 && editBooking.room_id) {
            const selectedRoom = rooms.find((room) => room.id === parseInt(editBooking.room_id));
            setSelectedPrice(selectedRoom ? selectedRoom.price : '');
        }
    }, [rooms, editBooking.room_id])

    const sendData = async (dataEdit) => {
        const requestData = dataEdit
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${apiUrl}/api/sys/edit-booking`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestData)
            });
            const data = await response.json();
            if (response.ok) {
                setMessageEditData(data.request.message)
            } else {
                setMessageEditData(data.request.message)
                console.error("Ошибка загрузки данных:", response.statusText);
            }
        } catch (error) {
            console.error("Ошибка:", error);
        }
    }
    return (
        <div className='system-background-hide'>
            <section className="system-container system-container-form">
                <div className='system-container__inner'>
                    <header className="system-header">Редактирование</header>
                    <form className="system-form">
                        <div className="system-input-box">
                            <label>Номер брони</label>
                            <input required placeholder="Имя Фамилия" value={editBooking.full_name} type="text" name='full_name' onChange={handleInputChange} />
                        </div>
                        {boolElemByRole(['admin', 'booker']) ? (
                        <div className="system-input-box">
                            <label>Серийный номер паспорта</label>
                            <input required placeholder="Серийный номер паспорта" value={editBooking.passport_serial_number} type="text" name='passport_serial_number' onChange={handleInputChange} />
                        </div>
                        ):(null)}
                        <div className="system-input-box">
                            <label>Полное имя гостя</label>
                            <input required placeholder="Имя Фамилия" value={editBooking.full_name} type="text" name='full_name' onChange={handleInputChange} />
                        </div>
                        <div className="system-input-box">
                            <label>Телефон</label>
                            <input required placeholder="Введите номер тел." value={editBooking.phone_number} type="telephone" name="phone_number" onChange={handleInputChange} />
                        </div>
                        <div className="system-input-box">
                            <label>Почта</label>
                            <input required placeholder="Введите почту" value={editBooking.email} type="telephone" name="email" onChange={handleInputChange} />
                        </div>
                        <div className="system-column">
                            <div className="system-input-box">
                                <label>Начало брони</label>
                                <input required value={editBooking.available_from} placeholder="Заезд" type="date" name='available_from' onChange={handleInputChange} />
                            </div>
                            <div className="system-input-box">
                                <label>Окончание брони</label>
                                <input required value={editBooking.available_to} placeholder="Выезд" type="date" name='available_to' onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className="system-input-box system-address">
                            <label>Тип номера</label>
                            <div className="system-column">
                                <div className="system-select-box">
                                    <select required value={editBooking.room_id || ''} onChange={handleInputChange} name='room_id'>
                                        {rooms && rooms.length > 0 ? (
                                            rooms.map((room) => (
                                                <option key={room.id} value={room.id}>{room.translations.room_type[language]}</option>
                                            ))
                                        ) : (
                                            <option value="">Нет номеров</option>
                                        )}

                                    </select>
                                </div>
                                <input placeholder={selectedPrice || "Цена не доступна"} disabled type="text" />
                            </div>
                        </div>
                        <div className='flex-btns'>
                            <button type='button' className="system-booking-table-btn system-booking-table-btn-cancel" onClick={onClose}>Закрыть</button>
                            <button type='button' className="system-booking-table-btn system-booking-table-btn-view" onClick={() => sendData(editBooking)}>Сохранить</button>
                        </div>
                        {Array.isArray(messageEditData) && messageEditData.length > 0 ? (
                            messageEditData.map((mess, index) => (
                                <span className='error-message' key={index + 1}>{mess}</span>
                            ))
                        ) : (null)}
                    </form>
                </div>
            </section>
        </div>
    );
}

export default BookingEdit;
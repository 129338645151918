import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLanguage } from '../LanguageContext';
import '../styles/scss/BookingButton.scss'
import { minDaysBooking, maxDaysBooking, maxDateBooking } from '../utils/vars';
import { fetchData } from '../utils/api';
import { apiUrl } from '../config';
import useAuthRedirect from '../utils/modules/useAuthRedirect';
import Protected from './system/Protected';

const BookingButton = () => {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [checkInDate, setCheckInDate] = useState("");
    const [checkOutDate, setCheckOutDate] = useState("");
    const [checkOutMin, setCheckOutMin] = useState("");
    const [checkOutMax, setCheckOutMax] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const { language } = useLanguage();

    const today = new Date()
    const minDateBooking = new Date(today)
    minDateBooking.setDate(today.getDate() + minDaysBooking)

    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const message = params.get('message');
  
      if (message) {
        alert(`Сообщение от банка: ${message}`);
        navigate('/', { replace: true });
      }
    }, [location, navigate]);

    const handleChange = (e) => {
      const { name, value } = e.target;
  
      if (name === "cin") {
        setCheckInDate(value);
  
        const selectedCheckInDate = new Date(value);
        const minDate = new Date(selectedCheckInDate);
        minDate.setDate(selectedCheckInDate.getDate() + minDaysBooking);
  
        const maxDate = new Date(maxDateBooking);
  
        setCheckOutMin(minDate.toISOString().split("T")[0]);
        setCheckOutMax(maxDate.toISOString().split("T")[0]);
  
        if (checkOutDate && (new Date(checkOutDate) < minDate || new Date(checkOutDate) > maxDate)) {
          setCheckOutDate("");
        }
      } else if (name === "cout") {
        setCheckOutDate(value);
      }
    };
  
    const handleSearch = async (event) => {
      
        event.preventDefault();
        if (checkInDate && checkOutDate) {
            const token = localStorage.getItem('accessToken');
            console.log()
            if (!token) {
              return;
            }
            const result = await fetchData(`${apiUrl}/api/${language}/search-rooms`, "POST", token, {body: {"checkIn": checkInDate, "checkOut": checkOutDate}})
            if (result?.request?.status === 0) {
              result.request.data.checkIn = checkInDate
              result.request.data.checkOut = checkOutDate
            }
            // Если уже на странице результатов
            if (location.pathname === "/book-rooms") {
                // Обновляем данные через состояние
                navigate("/book-rooms", { replace: true, state: { searchResults: result } });
            } else {
                // Переход на страницу результатов
                navigate("/book-rooms", { state: { searchResults: result } });
            }
        }
    };

  
    const langs = {
      ru: {
        check_dates: "Выберите даты",
        check_in: "Бронь с",
        check_out: "Бронь до",
        button_book: "Забронировать",
        registration: "Зарегистрироваться",
      },
      tm: {
        check_dates: "Senesi saýlaň",
        check_in: "Bering üçin",
        check_out: "Beringden soň",
        button_book: "Rezervirlemek",
        registration: "Registrasiýa",
      },
      en: {
        check_dates: "Select dates",
        check_in: "Check-in from",
        check_out: "Check-out until",
        button_book: "Book now",
        registration: "Registration",
      },
    };

  const role = localStorage.getItem('asd');
  const token = localStorage.getItem('accessToken');
  const toggleForm = () => {
    if (token && role === 'client') {
      setIsFormVisible(!isFormVisible) // Если всё ок, открываем форму
    } else {
      window.location.href = '/authorization'; // Если не авторизован, перенаправляем
    }
  };
  return (
    <div className="booking-container">
      {role === 'client' && token ? (
          <button className="booking-button" onClick={toggleForm}>
            {langs[language].button_book}
          </button>
      ) : (
        <a className="booking-button" href='/authorization'>{langs[language].registration}</a>
      )}
      {isFormVisible && (
        <Protected element={
          <div className="booking-form">
            <h3>{langs[language].check_dates}</h3>
            <form onSubmit={handleSearch}>
              <label htmlFor="cin">
                  {langs[language].check_in}:
                  <input
                      type="date"
                      id="cin"
                      name="cin"
                      value={checkInDate}
                      min={new Date(minDateBooking).toISOString().split("T")[0]}
                      max={new Date(maxDateBooking).toISOString().split("T")[0]}
                      onChange={handleChange}
                      required
                  />
              </label>
              <label htmlFor="cout">
                  {langs[language].check_out}:
                  <input
                      type="date"
                      id="cout"
                      name="cout"
                      value={checkOutDate}
                      min={checkOutMin}
                      max={checkOutMax}
                      onChange={handleChange}
                      required
                  />
              </label>
              <button type="submit">{langs[language].button_book}</button>
            </form>
          </div>
        } requiredRoles={['client']} url='/authorization'/>
      )}
    </div>
  );
};

export default BookingButton;

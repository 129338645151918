import React, { useEffect, useState } from 'react';

import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import RoomCard from '../components/RoomsPage/RoomCard';

import { fetchData } from '../utils/api'
import { apiUrl } from '../config'
import { useLanguage } from '../LanguageContext';
import { useLocation } from 'react-router-dom';


function BookRooms() {
    const { language } = useLanguage();
    const location = useLocation();
    const [bookingPrice, setBookingPrice] = useState(0);

    // Состояние для языка
    const langs = {
        'ru': {
            'title': "Апартаменты",
            'message': "Нет свободных номеров на период выбранных дат."
        },
        'tm': {
            'title': "Otaglar",
            'message': "Saýlanan seneler üçin otag ýok."
        },
        'en': {
            'title': "Apartments",
            'message': "There are no available rooms for the selected dates."
        }
    };

    const [searchResults, setSearchResults] = useState(location.state?.searchResults?.request?.data || null);
    const [searchResultsMessage, setSearchResultsMessage] = useState(location.state?.searchResults?.request?.message || langs[language].message);

    // Обновляем результаты при каждом новом состоянии
    useEffect(() => {
        if (location.state?.searchResults) {
            if (location.state.searchResults.request.status === 0) {
                setSearchResults(location.state.searchResults.request.data);
            }
            setSearchResultsMessage(location.state.searchResults.request.message)
        }
    }, [location.state]);

  return (
      <React.Fragment>
          <HeaderComponent />
          <h1 className='rooms-title'>{langs[language].title}</h1>
            <p className='rooms-page-list-message'>
                {searchResultsMessage}:
                {searchResults?.checkIn && searchResults?.checkOut &&
                    " " + searchResults.checkIn + " - " + searchResults.checkOut
                }
            </p>
          <div className="rooms-page-list container">
                {searchResults && searchResults.map((room, index) => (
                    <RoomCard
                        key={index}
                        typePage={'book'}
                        room={room}
                        checkIn={searchResults.checkIn}
                        checkOut={searchResults.checkOut}
                        bookingPrice={bookingPrice}
                    />
                ))}
          </div>
      </React.Fragment>
  );
}

export default BookRooms;

import React, { useEffect, useState } from 'react';
import { intervalUpdatingData, showStatusBooking } from '../../../utils/dynamic';

import { fetchData, formatDate } from '../../../utils/api';
import { apiUrl } from '../../../config';
import BookingView from '../bookings/BookingView'
import { useLanguage } from '../../../LanguageContext';

const ReceptionConfirmBookings = () => {
  const { language } = useLanguage();
  const [bookings, setBookings] = useState([])
  const [bookingsMessage, setBookingsMessage] = useState([])
  const [messageDelBook, setMessageDelBook] = useState('')
  if (messageDelBook.length !== 0) {
    setTimeout(() => {
      setMessageDelBook('')
    }, 3000)
  }
  const [loading, setLoading] = useState(false); // состояние для отслеживания загрузки

  const [isViewOpen, setIsViewOpen] = useState(false); // состояние для просмотра записи
  const [viewData, setViewData] = useState([]); // состояние с данными для просмотра записи

  const [searchQuery, setSearchQuery] = useState(''); // состояние для поиска

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' }); // состояние для сортировки по дате заезда/выезда

  // Handle VIEW/EDIT/DELETE click
  const handleVEDNClick = (booking, action) => {
    switch (action) {
      case 'view':
        setViewData(booking)
        setIsViewOpen(true);
        break;
      default:
        break
    }
  };
  // Handle VIEW/EDIT closer
  const handleVENClose = () => {
    setIsViewOpen(false);
  };

  const fetchGAB = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      const data = await fetchData(`${apiUrl}/api/sys/get-active-bookings-recep-confirmed`, "GET", token)
      if (data.request.status === 0) {
        // Обработка данных и связывание booking с соответствующим room
        const processedData = data.request.data.booking.map((booking) => {
          const room = data.request.data.rooms.find((room) => room.id === booking.room_id);
          // const payment = data.request.data.payments.find((payment) => payment.id === booking.payment_id);
          const customer = data.request.data.customers.find((customer) => customer.id === booking.customer_id);
          return {
            ...booking,
            room_type: room ? room.translations.room_type[language] : 'Неизвестно', // Тип номера
            price: room ? room.price : 'Неизвестно', // Цена за первые сутки номера
            book_price: data.request.data.book_price ? data.request.data.book_price : 'Неизвестно', // Цена за бронь
            passport_serial_number: customer ? customer.passport_serial_number : 'Неизвестно', // Серия паспорта
            cardholdername: customer ? customer.cardholdername : 'Неизвестно', // 4 цифры карты
            full_name: customer ? customer.full_name : 'Неизвестно', // Полное 
            email: customer ? customer.email : 'Неизвестно', // Почта
            phone_number: customer ? customer.phone_number : 'Неизвестно', // Номер тел
          };
        });
        setBookings(processedData);
        setLoading(false); // Останавливаем загрузку
      } else {
        setBookingsMessage(data.request.message);
      }
    } catch (error) {
      console.error("Ошибка:", error);
    }
  };
  useEffect(() => {
    fetchGAB();
    // Обновление данных каждые n секунд
    const interval = setInterval(() => {
      setLoading(true)
      setTimeout(() => {
        fetchGAB();
      }, 3000)
    }, intervalUpdatingData);
    // Очистка интервала при размонтировании компонента
    return () => clearInterval(interval);
  }, []);



  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredBookings = bookings.filter((booking) => 
    (booking.card_number && booking.card_number.includes(searchQuery)) ||
    (booking.passport_serial_number && booking.passport_serial_number.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.full_name && booking.full_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.phone_number && booking.phone_number.includes(searchQuery)) ||
    (booking.available_from && booking.available_from.includes(searchQuery)) ||
    (booking.available_to && booking.available_to.includes(searchQuery)) ||
    (booking.book_number && booking.book_number.includes(searchQuery))
  );
  const handleSort = (key) => {
    let direction = 'asc';
  
    // Меняем направление, если сортируем по тому же полю
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedAndFilteredBookings = [...filteredBookings].sort((a, b) => {
    if (sortConfig.key) {
        const dateA = new Date(formatDate(a[sortConfig.key]));
        const dateB = new Date(formatDate(b[sortConfig.key]));
        if (dateA < dateB) return sortConfig.direction === 'asc' ? -1 : 1;
        if (dateA > dateB) return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  // Функция для отображения стрелки
  const renderSortArrow = (key) => {
    if (sortConfig.key === key) {
        return sortConfig.direction === 'asc' ? '▲' : '▼';
    }
    return "▼"; // стрелка по умолчанию
  };

  return (
      <>
        <section className="system-bookings-table">
          <h2 className='system-bookings-table__title'>Подтвержденные</h2>
          <div className='d-flex just-content-sb'>
            <div className='d-flex just-content-sb'>
              <input 
                  type="text" 
                  placeholder="Поиск по тел/имени..."
                  value={searchQuery} 
                  onChange={handleSearchChange} 
                  className="search-input search-field" 
              />
            </div>

            {loading && (
              <div className="loader">
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
              </div>
            )}
          </div>
          <h3 className='success-message'>{messageDelBook}</h3>

          <table className='system-table'>
            <thead className='system-thead'>
              <tr className='system-tr'>
                <th className='system-th'>№</th>
                <th className='system-th'>Тип номера</th>
                <th className='system-th search-field'>Серия паспорта</th>
                <th className='system-th search-field'>Имя гостя</th>
                <th className='system-th search-field'>Плательщик</th>
                <th className='system-th search-field'>Тел.</th>
                <th className='system-th system-th__cursor' onClick={() => handleSort('available_from')}>Дата заезда {renderSortArrow('available_from')}</th>
                <th className='system-th system-th__cursor' onClick={() => handleSort('available_to')}>Дата выезда {renderSortArrow('available_to')}</th>
                <th className='system-th'>Статус</th>
                <th className='system-th'>Общая сумма</th>
                <th className='system-th'>Действия</th>
              </tr>
            </thead>
            <tbody className='system-tbody'>

            {sortedAndFilteredBookings && sortedAndFilteredBookings.length > 0 ? (
              <React.Fragment>
              {sortedAndFilteredBookings.map((booking, index) => (
                <tr className="system-tr" key={index + 1}>
                  <td className="system-td">{booking.book_number}</td>
                  <td className="system-td">{booking.room_type}</td>
                  <td className="system-td">{booking.passport_serial_number}</td>
                  <td className="system-td">{booking.full_name}</td>
                  <td className="system-td">{booking.cardholdername}</td>
                  <td className="system-td">{booking.phone_number}</td>
                  <td className="system-td">{booking.available_from}</td>
                  <td className="system-td">{booking.available_to}</td>
                  <td className="system-td">{showStatusBooking(booking.status)}</td>
                  <td className="system-td">{(parseFloat(booking.price) + parseFloat(booking.book_price)).toFixed(2)}</td>
                  <td className="system-td">
                    <button className="system-booking-table-btn system-booking-table-btn-view" onClick={() => handleVEDNClick(booking, 'view')}>Посмотреть</button>
                  </td>
                </tr>
              ))}
              </React.Fragment>
            ) : (
              <tr>
                <td className="system-td">{bookingsMessage}</td>
              </tr>
            )}
            </tbody>
          </table>
        </section>
        {isViewOpen && (
          <BookingView booking={viewData} actionFetchGAB={fetchGAB} onClose={handleVENClose} />
        )}
      </>
  );
};

export default ReceptionConfirmBookings;
import React, { useState } from 'react';


const UserView = ({ user, onClose }) => {
    const [viewUser] = useState(user);
    return (
        <div className='system-background-hide'>
            <section className="system-container">
                <div className='system-container__inner'>
                    <header className="system-header">Просмотр</header>
                    <div className="system-form">
                        <div className="system-input-box">
                            <label>Login</label>
                            <input disabled placeholder="Придумайте имя пользователя" value={viewUser.username} type="text" name='username' />
                        </div>
                        <div className="system-input-box system-address">
                            <label>Роль</label>
                            <div className="system-column">
                                <div className="system-select-box">
                                    <select disabled value={viewUser.role_id || ''} name='role_id'>
                                        {viewUser.role_name ? (
                                                <option>{viewUser.role_name}</option>
                                        ) : (
                                            <option value="">Роль не установлена</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='flex-btns'>
                            <button type='button' className="system-booking-table-btn system-booking-table-btn-cancel" onClick={onClose}>Закрыть</button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default UserView;
import React from 'react';
import { useLanguage } from '../../LanguageContext';
const LanguageChanger = () => {
  const { language, setLanguage } = useLanguage();

  const changeLanguage = (event) => {
    setLanguage(event.target.value); // обновляем язык в состоянии
  };

  return (
    <div className="language-selector">
      <label htmlFor="language">Язык</label>
      <select id="language" value={language} onChange={changeLanguage}>
        <option value="ru" defaultChecked>RU</option>
        <option value="tm">TM</option>
        <option value="en">EN</option>
        {/* Добавьте другие языки, если нужно */}
      </select>
    </div>
  );
};

export default LanguageChanger;
import React, { useState } from 'react';
import { fetchData } from '../../utils/api';
import { apiUrl } from '../../config';
import axios from 'axios';

const ClientBooksComponent = ({ bookingId }) => {
    const [passportFile, setPassportFile] = useState(null);
    const [applicationFile, setApplicationFile] = useState(null);
    const [message, setMessage] = useState('');
  
    const handleFileChange = (e, setFile) => {
      setFile(e.target.files[0]);
    };
  
    const handleDownload = async () => {
      // Путь к шаблону заявления на сервере или в public директории
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(`${apiUrl}/api/client/get-blank`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',  // Указываем тип ответа как blob (для бинарных данных)
        });
          
        // Создаём ссылку на Blob и инициируем скачивание
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'положение-по-возврату-средств-бронирования-номера.pdf'); // Имя файла
        document.body.appendChild(link);
        link.click();

        // Очистка ресурсов
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };
  
    const handleButton = async () => {
      if (!passportFile || !applicationFile) {
        alert('Пожалуйста, загрузите оба файла.');
        return;
      }

      const formdata = {
        'booking_id': bookingId,
        'passport': passportFile,
        'application': applicationFile,
      }
  
      // Пример запроса на сервер
      const token = localStorage.getItem('accessToken');
      const data = await fetchData(`${apiUrl}/api/client/book-cancel`, "POST", token, {body: formdata}, "multipart/form-data")
      if (data.request) {
        setMessage(data.request.message)
      }
    };

    return (
        <section className='system-background-hide'>
            <div className="document-upload-form">
                <h3>Загрузка документов</h3>
                <button onClick={handleDownload}>Получить заявление</button>
                {/* <p></p> */}
                <form>
                    <div>
                        <label>Скан паспорта:</label>
                        <input type="file" accept=".jpg,.jpeg,.png,.pdf" onChange={(e) => handleFileChange(e, setPassportFile)} />
                    </div>
                    <div>
                        <label>Заполненное заявление:</label>
                        <input type="file" accept=".docx" onChange={(e) => handleFileChange(e, setApplicationFile)} />
                    </div>
                    {message}
                    <button type="button" onClick={() => handleButton()}>Отправить</button>
                </form>
            </div>
        </section>
    );
  }
  

export default ClientBooksComponent;
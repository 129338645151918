// src/utils/api.js
export async function fetchData(url, method = 'GET', token = '', options = {}, CT = 'application/json') {
    const headers = new Headers();

    // Устанавливаем Content-Type
    if (CT !== null) {
        headers.append('Content-Type', CT);
    }

    // Добавляем заголовок Authorization
    headers.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
        method: method.toUpperCase(), // Убедимся, что метод в верхнем регистре
        headers: headers,
        ...options, // Добавляем любые другие опции (например, body)
    };

    // Если метод не GET и есть тело запроса
    if (requestOptions.method !== 'GET' && options.body) {
        if (CT === 'application/json') {
            // Если нужно отправить JSON, сериализуем тело в строку
            requestOptions.body = JSON.stringify(options.body);
        } else if (CT === 'multipart/form-data') {
            // Если нужно отправить файл, используем FormData
            const formData = new FormData();
            for (const key in options.body) {
                formData.append(key, options.body[key]);
            }
            requestOptions.body = formData;
            // Убираем Content-Type, потому что браузер автоматически установит его для FormData
            headers.delete('Content-Type');
        }
    }

    try {
        const response = await fetch(url, requestOptions);

        if (!response.ok) {
            console.log(`Ошибка: ${response.status} ${response.statusText}`);
            throw new Error(`Error: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Ошибка при запросе:', error);
        throw error;
    }
}

export function formatDate(dateInput) {
    // Разделяем дату и время
    const [datePart] = dateInput.split(" ");
    const [day, month, year] = datePart.split(".");

    // Преобразуем в формат yyyy-mm-dd
    return `${year}-${month}-${day}`;
}
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiUrl } from '../../config';
import axios from 'axios';
import useAuthRedirect from '../../utils/modules/useAuthRedirect';

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // Проверка авторизации при загрузке компонента
  useAuthRedirect('client', '/system', '/login');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/api/login`, {
        username,
        password
      });
      const token = response.data.token;
      const asd = response.data.asd;
      localStorage.setItem('accessToken', token);
      localStorage.setItem('asd', asd);

      alert('Авторизация успешна');
      let url = '/system'
      if (asd === 'reception') {
        url = '/system/reception'
      }
      navigate(url);

      window.location.reload();
      // Перезагружаем страницу после перенаправления
    } catch (error) {
      alert('Ошибка авторизации');
    }
  };

  return (
    <div className='login-form-backg'>
      {/* <form class="login-form" onSubmit={handleLogin}>
        <p class="login-form-title">Система</p>
        <div class="login-input-container">
          <input type="text" placeholder="Имя пользователя" value={username} onChange={(e) => setUsername(e.target.value)} />
          <span>
          </span>
        </div>
        <div class="login-input-container">
          <input type="password" placeholder="Введите пароль" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <button type="submit" class="login-submit">Войти</button>
      </form> */}


      <form className="login-form" onSubmit={handleLogin}>
          <span className="input-span">
            <label for="username" className="login-label">Имя пользователя</label>
            <input type="text" name='username' value={username} onChange={(e) => setUsername(e.target.value)} /></span>
          <span className="input-span">
            <label for="password" className="login-label">Пароль</label>
            <input type="password" name='password' value={password} onChange={(e) => setPassword(e.target.value)} /></span>
          <button type="submit" className="submit">Войти</button>
        </form>

    </div>
  );
}

export default Login;

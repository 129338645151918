import React, { useState } from 'react';

function GalleryComponent() {
  const images = [
    { id: 1, image: '/media/img/gallery/gallery01.jpg', title: 'Услуга 1', minidescription: 'Описание услуги 1' },
    { id: 2, image: '/media/img/gallery/gallery02.jpg', title: 'Услуга 2', minidescription: 'Описание услуги 2' },
    { id: 3, image: '/media/img/gallery/gallery03.jpg', title: 'Услуга 3', minidescription: 'Описание услуги 3' },
    { id: 4, image: '/media/img/gallery/gallery04.jpg', title: 'Услуга 4', minidescription: 'Описание услуги 4' },
    { id: 5, image: '/media/img/gallery/gallery05.jpg', title: 'Услуга 5', minidescription: 'Описание услуги 5' },
    { id: 6, image: '/media/img/gallery/gallery06.jpg', title: 'Услуга 6', minidescription: 'Описание услуги 6' },
    { id: 7, image: '/media/img/gallery/gallery07.jpg', title: 'Услуга 7', minidescription: 'Описание услуги 7' },
    { id: 8, image: '/media/img/gallery/gallery08.jpg', title: 'Услуга 8', minidescription: 'Описание услуги 8' },
    { id: 9, image: '/media/img/gallery/gallery09.jpg', title: 'Услуга 9', minidescription: 'Описание услуги 9' },
    { id: 10, image: '/media/img/gallery/gallery10.jpg', title: 'Услуга 10', minidescription: 'Описание услуги 10' },
    { id: 11, image: '/media/img/gallery/gallery11.jpg', title: 'Услуга 11', minidescription: 'Описание услуги 11' },
    { id: 12, image: '/media/img/gallery/gallery12.jpg', title: 'Услуга 12', minidescription: 'Описание услуги 12' },
    { id: 13, image: '/media/img/gallery/gallery13.jpg', title: 'Услуга 13', minidescription: 'Описание услуги 13' },
    { id: 14, image: '/media/img/gallery/gallery14.jpg', title: 'Услуга 14', minidescription: 'Описание услуги 14' },
    { id: 15, image: '/media/img/gallery/gallery15.jpg', title: 'Услуга 15', minidescription: 'Описание услуги 15' },
    { id: 16, image: '/media/img/gallery/gallery16.jpg', title: 'Услуга 16', minidescription: 'Описание услуги 16' },
    { id: 17, image: '/media/img/gallery/gallery17.jpg', title: 'Услуга 17', minidescription: 'Описание услуги 17' },
    { id: 18, image: '/media/img/gallery/gallery18.jpg', title: 'Услуга 18', minidescription: 'Описание услуги 18' },
    { id: 19, image: '/media/img/gallery/gallery19.jpg', title: 'Услуга 19', minidescription: 'Описание услуги 19' },
    { id: 20, image: '/media/img/gallery/gallery20.jpg', title: 'Услуга 20', minidescription: 'Описание услуги 20' },
    { id: 21, image: '/media/img/gallery/gallery21.jpg', title: 'Услуга 21', minidescription: 'Описание услуги 21' },
    { id: 22, image: '/media/img/gallery/gallery22.jpg', title: 'Услуга 22', minidescription: 'Описание услуги 22' },
    { id: 23, image: '/media/img/gallery/gallery23.jpg', title: 'Услуга 23', minidescription: 'Описание услуги 23' },
    { id: 24, image: '/media/img/gallery/gallery24.jpg', title: 'Услуга 24', minidescription: 'Описание услуги 24' },
    { id: 25, image: '/media/img/gallery/gallery25.jpg', title: 'Услуга 25', minidescription: 'Описание услуги 25' },
    { id: 26, image: '/media/img/gallery/gallery26.jpg', title: 'Услуга 26', minidescription: 'Описание услуги 26' },
    { id: 27, image: '/media/img/gallery/gallery27.jpg', title: 'Услуга 27', minidescription: 'Описание услуги 27' },
  ];

    // Состояние для модального окна и выбранного изображения
    const [isOpen, setIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    // Открытие модального окна с выбранной картинкой
    const openModal = (image) => {
        setSelectedImage(image);
        setIsOpen(true);
    };

    // Закрытие модального окна
    const closeModal = () => {
        setIsOpen(false);
        setSelectedImage(null);
    };

    const savedLanguage = localStorage.getItem('language') || 'tm';
    // Состояние для языка
    const langs = {
      'ru': {
        'title': "Галерея",
      },
      'tm': {
        'title': "Galereýa",
      },
      'en': {
        'title': "Gallery",
      }
    };

    return (
        <section className="gallery container">
        <h3 className="gallery-title">{langs[savedLanguage].title}</h3>
        <div className="row">
          <ul>
            {images.map((item, index) => (
              <li key={index} onClick={() => openModal(item.image)}>
                <img src={item.image} alt={`Item ${index}`} />
              </li>
            ))}
          </ul>
        </div>
  
        {/* Модальное окно для отображения выбранной картинки на полный экран */}
        {isOpen && (
          <div className="modal" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <img src={selectedImage} alt="Full size" />
              <button className="close-button" onClick={closeModal}>X</button>
            </div>
          </div>
        )}
      </section>
    );
}

export default GalleryComponent;

// RoomSlider.jsx
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules'; // Импорт модулей
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { fetchData } from '../../utils/api';
import { apiUrl } from '../../config';
import { useNavigate } from "react-router-dom";
import { useLanguage } from '../../LanguageContext';


const SliderRoomsComponent = () => {
  const { language } = useLanguage();
  const [rooms, setRooms] = useState([]);
  const [bookingPrice, setBookingPrice] = useState([]);

  const navigate = useNavigate()
  useEffect(() => {
    const fetchRooms = async () => {
      const response = await fetchData(`${apiUrl}/api/${language}/get-rooms`, "GET")
      if (response.request && response.request.status === 0) {
        setRooms(response.request.data.rooms)
        setBookingPrice(response.request.data.booking_price)
      }
    }
    
    fetchRooms()
  }, [language]);

  const savedLanguage = localStorage.getItem('language') || 'tm';
  // Состояние для языка
  const langs = {
    'ru': {
      'more_btn': "Детали",
      'max_guests_title': "Гостей",
      'rooms_title': "Апартаменты",
    },
    'tm': {
      'more_btn': "Maglumat",
      'max_guests_title': "Myhmanlaryň sany",
      'rooms_title': "Apartamentlar",
    },
    'en': {
      'more_btn': "More",
      'max_guests_title': "Guests",
      'rooms_title': "Apartments",
    }
  };
  return (
    <div className="container room-slider">
      <h1>{langs[savedLanguage].rooms_title}</h1>
      <Swiper
        slidesPerView={3}    // Отображать 3 блока одновременно
        spaceBetween={30}    // Отступ между слайдами
        pagination={{ clickable: true }} // Включить пагинацию
        autoplay={{                  // Включить автопрокрутку
          delay: 3000,               // Задержка в миллисекундах (3 секунды)
          disableOnInteraction: false, // Продолжать прокрутку после взаимодействия пользователя
        }}
        modules={[Autoplay]}
        breakpoints={{        // Адаптивные настройки
          320: {              // Для экранов шириной от 320px (мобильные)
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {             // Для экранов шириной от 768px (планшеты)
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {            // Для экранов шириной от 1024px (настольные компьютеры)
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
      >
        {rooms.map((room) => (
          <SwiperSlide key={room.id}>
            <div className="room-card">
              <img src={`${apiUrl}/api/images/${room.images[0]}`} alt={room.translations.room_type} className="room-image" />
              <h3 className="room-name">{room.translations.room_type}</h3>
              <a href={`/rooms/${room.id}/view`} className="room-details-link"
                onClick={(e) => {
                e.preventDefault()
                navigate(`/rooms/${room.id}/view`, { state: { room,  bookingPrice } })
                }}>{langs[savedLanguage].more_btn}</a>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SliderRoomsComponent;

import React, { useEffect, useState } from 'react';
import useAuthRedirect from '../../utils/modules/useAuthRedirect';
import { fetchData } from '../../utils/api';
import { apiUrl } from '../../config';
import HeaderComponent from '../HeaderComponent';
import FooterComponent from '../FooterComponent';
import ClientBookCancelComponent from './ClientBookCancelComponent'
import { useLanguage } from '../../LanguageContext';
import { useLocation } from 'react-router-dom';

const ClientBooksComponent = () => {  
  const { language } = useLanguage();
  const [bookings, setBookings] = useState([]);
  const [bookingId, setBookingId] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const message = queryParams.get("message");
  // Проверка авторизации при загрузке компонента
  // useAuthRedirect('client', '/books', '/authorization');
  const fetchGAB = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      // Загружаем брони клиента (замени URL на свой эндпоинт)
      const response = await fetchData(`${apiUrl}/api/client/bookings`, "GET", token)
      if (response.request.status === 0) {
        const processedData = response.request.data.booking.map((booking) => {
          const room = response.request.data.rooms.find((room) => room.id === booking.room_id);
          const customer = response.request.data.customers.find((customer) => customer.id === booking.customer_id);
          return {
            ...booking,
            room_type: room ? room.translations.room_type[language] : 'Неизвестно', // Тип номера
            price: room ? room.price : 'Неизвестно', // Цена за первые сутки номера
            book_price: response.request.data.book_price ? response.request.data.book_price : 'Неизвестно', // Цена за бронь
            full_name: customer ? customer.full_name : 'Неизвестно', // Полное 
            email: customer ? customer.email : 'Неизвестно', // Почта
            phone_number: customer ? customer.phone_number : 'Неизвестно', // Номер тел
          };
        });
        setBookings(processedData)
      }
    } catch (error) {
      console.error('Ошибка: ', error)
    }
  }

  const openForm = (id) => {
    setIsFormOpen(true)
    setBookingId(id)
  }
  const cancelBook = async (id) => {
    const token = localStorage.getItem('accessToken');
    const response = await fetchData(`${apiUrl}/client/book-cancel?id=${id}`, "GET", token)
  }

  const getStatusMessage = (status, lang = 'ru') => {
    const messages = {
      'ru': {
        0: "Успешно",
        1: "При заселении",
        2: "Ожидается",
        3: "Произведен возврат средств",
      },
      'tm': {
        0: "Başaraly",
        1: "Gelişde",
        2: "Gözlenilýär",
        3: "Puly yzyna gaýtarmak geçirildi",
      },
      'en': {
        0: "Successful",
        1: "At check-in",
        2: "Pending",
        3: "Refund processed",
      },
    };
  
    return messages[lang][status] || '';
  };

  // Состояние для языка
  const langs = {
    'ru': {
      'books_title': "Ваши бронирования",
      'room_type': "Тип номера",
      'book_number': "Номер брони",
      'full_name': "Имя фамилия",
      'check_in': "Заезд",
      'check_out': "Выезд",
      'status': "Статус оплаты",
      'amount': "Общая сумма",
      'message': "У вас пока нет бронирований.",
    },
    'tm': {
      'books_title': "Bronlaryňyz",
      'room_type': "Otag görnüşi",
      'book_number': "Bronlama belgisi",
      'full_name': "Ady we familiýasy",
      'check_in': "Geliş wagty",
      'check_out': "Çykyş wagty",
      'status': "Töleg ýagdaýy",
      'amount': "Jemi mukdar",
      'message': "Sizde henizem bronlama ýok.",
    },
    'en': {
      'books_title': "Your bookings",
      'room_type': "Room type",
      'book_number': "Booking number",
      'full_name': "Full name",
      'check_in': "Check-in",
      'check_out': "Check-out",
      'status': "Payment status",
      'amount': "Total amount",
      'message': "You don't have any bookings yet.",
    }
  };
  useEffect(() => {
    if (message) {
      // Выводим его в alert
      alert(message);
    }
    fetchGAB()
  }, []);
  return (
    <React.Fragment>
      <HeaderComponent />
      <div className="books-container">
        <h1 className="books-title">{langs[language].books_title}</h1>
        {bookings.length > 0 ? (
          <ul className="books-list">
            {bookings.map((booking) => (
              <li key={booking.id} className="booking-item">
                <h3>{langs[language].room_type}: {booking.room_type}</h3>
                <p>{langs[language].book_number}: {booking.book_number}</p>
                <p>{langs[language].full_name}: {booking.full_name}</p>
                <p>{langs[language].check_in}: {booking.available_from}</p>
                <p>{langs[language].check_out}: {booking.available_to}</p>
                <p>{langs[language].status}: {getStatusMessage(booking.status, language)}</p>
                <p>{langs[language].amount}: {booking.amount} TMT</p>
                {/* {booking.status !== 3 &&
                  <button className='abutton abutton-return-book' onClick={() => openForm(booking.id)}>Отменить бронь</button>
                } */}
              </li>
            ))}
          </ul>
        ) : (
          <p>{langs[language].message}</p>
        )}
      </div>
      {isFormOpen && (
        <ClientBookCancelComponent bookingId={bookingId} />
      )}
      <FooterComponent />
    </React.Fragment>
  )
}

export default ClientBooksComponent;
import React from 'react';

const ServicesComponent = () => {
  const services_ru = [
    {
      id: 1,
      image: '/media/img/services/services.jpg',
      title: 'Конференц зал',
      undertitle: 'для переговоров на 20 человек',
      description: `Конференц зал – Мы создаем оптимальные условия для того, чтобы наши
гости смогли решить все свои деловые вопросы прямо здесь, в здании отеля,
не теряя ни минуты своего драгоценного времени. Конференц- зал оснащен
всем необходимым презентационным оборудованием.`,
    },
    {
      id: 2,
      image: '/media/img/services/services2.jpg',
      title: 'Рестораны',
      undertitle: '',
      description: `По утрам в ресторане готовят вкуснейшие завтраки. Для гостей
действует формат &quot;шведский стол&quot;, где вы можете сами составить для себя
правильный сбалансированный завтрак. На обед и ужин - вкусные блюда и
роскошные напитки. Приготовленные шеф-поварами, специалистами в своей
области, традиционные и непревзойденные блюда удовлетворят вкус любого
гурмана. Вы можете уединиться своей компанией для проведения делового
ужина или частного мероприятия`,
    },
    {
      id: 3,
      image: '/media/img/services/services3.jpg',
      title: 'VIP Лобби - бар',
      undertitle: '',
      description: `Лобби – бар – это одно из самых изысканных и комфортных мест нашего
отеля. Наш лобби – бар сочетает в себе роскошь интерьера, уют и
непринужденную атмосферу, располагающую одновременно к открытой,
дружеской беседе.`,
    },
    {
      id: 4,
      image: '/media/img/services/services4.jpg',
      title: 'Оздоровительный центр',
      undertitle: 'предлагает различные виды процедур',
      description: `Массаж, Фито бочка, Гидромассаж, инфракрасная лампа, минерал – солевые
пузырьковые ванны, вибромассаж, магнитно - вакуумная терапия,
ультразвук терапия, лазерная терапия , электрофорез, ингаляция,
косметология.

SPA центр - Насладитесь полным комплексом услуг одного из лучших SPA в
Авазе. Уникальный оздоровительный SPA-комплекс, где гости могут
получить широкий спектр услуг оздоровительного и лечебно-
профилактического характера, попробовать процедуры косметологии для
лица и тела.

Дополнительные услуги оздоровительного центра:
Сауна
Турецкий Хамам
Массаж
Стоматология
Тренажерный зал`,
    },
    {
      id: 5,
      image: '/media/img/services/services5.jpg',
      title: 'Развлекательный центр',
      undertitle: 'для детей и взрослых с бассейном, аквапарком с душевыми кабинами и раздевалками.',
      description: `К услугам отдыхающих:
детская комната, детская игровая зона
Бильярд
4 – рядный Боулинг
Мини гольф
Теннисные корты
Компьютерная игротека
Плейстейшн
Вип бассейн
Два открытых бассейна
Три крытых бассейна – включая бассейн с искусственной волной
Крытый аквапарк
Бары у бассейнов
Летняя дискотека
Караоке бар
Кафе летнего и зимнего типа
Кинотеатр`,
    },
    {
      id: 6,
      image: '/media/img/services/services6.jpg',
      title: 'Прочие услуги:',
      undertitle: 'для детей и взрослых с бассейном, аквапарком с душевыми кабинами и раздевалками.',
      description: `Магазины – магазин одежды, магазин детских игрушек, магазин продуктов
Парикмахерская
Скоростной бесплатный Wi-Fi.`,
    }
  ];
  const services_tm = [
    {
      id: 1,
      image: '/media/img/services/services.jpg',
      title: 'Gepleşikler üçin VIP-zallar',
      undertitle: 'sygymy 20 adama çenli.',
      description: 'Biz myhmanlarymyza ähli iş meselesini ýalňyşlyk edýändirler, otelimiziň binasynda çözmäge mümkinçilik berýäris, hiç bir minut ýok ýerini ýitirmän. Konferensiýa zaly ähli zerur presektiw enjamlary bilen üpjün edilendir.',
    },
    {
      id: 2,
      image: '/media/img/services/services2.jpg',
      title: 'Restoranlar',
      undertitle: '',
      description: 'Erte bilen size ýyly kafe we nahar garaşýar, agşamlar täsin içgiler, dünýäniň dürli künjeklerinden inedördül tagamlar we ajaýyp deňiz manzara. Şefler tarapyndan taýýarlanan, hünärine mahsus kögelere, adaty we deňi-taýy bolmadyk tagamlar, her bir gurmanyň ýüregini hoş edýär.',
    },
    {
      "id": 3,
      "image": "/media/img/services/services3.jpg",
      "title": "Lobby bar",
      undertitle: '',
      "description": "Lobbi bar - ýakymly atmosferanyň, rahatlandyryjy sazyň we rahat yşyklandyryşyň utgaşmasydyr. Gepleşikleriňizde saýlama içgiler, özboluşly kokteýller we ýeňil garbanmalar bolar."
    },
    {
      "id": 4,
      "image": "/media/img/services/services4.jpg",
      "title": "Sagaldyş merkezi",
      undertitle: 'dürli görnüşli proseduralary hödürleýär',
      "description": "Massaž, fito-çelek, gidromassaž, infragyzyl lampa, mineral-duz köpükli hammamlar, wibromassaž, wakuum-magnit bejergisi, ultrases terapiýasy, lazer bejergisi, elektroforez, dem alyş (ingalýasiýa), saunalar, türk hammam."
    },
    {
      "id": 5,
      "image": "/media/img/services/services4.jpg",
      "title": "VIP-SPA merkezi",
      undertitle: '',
      "description": `Awazadaky iň gowy SPA-laryň biriniň
hyzmatlarynyň doly toplumyndan lezzet alyň. Saglygy we gözelligi
gowulandyrmak üçin programmalaryň giň topary dynç almaga we
bedeniň dikeldilmegine gönükdirilendir. Biz günüň islendik
wagtynda proseduralary hödürleýäris: oýanmak üçin irdenki ritual,
dikelmek üçin günortanlyk ritual, dynç almak üçin agşamky ritual
we ajaýyp görnüşi saklamak üçin günüň dowamynda gözellik idegi.
Stomatologiýa.
Dermanhana.`
    },
    {
      "id": 6,
      "image": "/media/img/services/services5.jpg",
      "title": "Ýerine ýetirilen beýleki hyzmatlar",
      undertitle: '',
      "description": `Fitnes-zal - täze başlaýanlar we professionallar üçin amatly sport
enjamlary bilen enjamlaşdyrylan. Bu ýerde dürli görnüşli fitnes we
güýç türgenleşiklerini ýerine ýetirip bilersiňiz. Aýry-aýry şkaflar we
duş kabinalar bilen geýim çalşylýan otaglar bar.
Tomusky açyk howuz - giň we gyzgyn, myhmanhananyň ähli
myhmanlarynyň amatly ýerleşmegi üçin niýetlenendir. Ýaş
myhmanlar üçin suw çüwdürimler we suw depejikleri bilen çagalar

bölümi bar. Howuzyň açylýan wagtlarynda dynç alyşyňyzy howpsuz
we aladasyz edýän halas edijiler bar.
Ýapyk howuz emeli tolkunly.
Açyk we ýapyk akwapark.
Tomusky we gyşky görnüşli kafeler.
Dükanlar​
Kinoteatr.
Tomus diskotekasy.
Karaoke-zal.
Dellekhana.
Ýokary tizlikli mugt Wi-Fi.
Güýmenje merkezi - çagalar we ulular üçin basseýn we akwaparkly.
Merkez duş kabinalary we geýim çalşylýan otaglar bilen
enjamlaşdyrylan. Ýaş myhmanlar üçin çagalar kafesi, oýunlar otagy,
kinoteatr we çagalar otagy bar.
Bilýard.
4 zolakly bouling meýdançasy.
Mini-golf, tennis kortlary, plýaž oýunlary we çagalar meýdançalary.
Namaz otagy`
    }
  ];
  const services_en = [
    {
      id: 1,
      image: '/media/img/services/services.jpg',
      title: 'VIP Meeting Rooms for Personal Meetings and Negotiations',
      undertitle: '',
      description: 'We create optimal conditions for our guests to resolve all their business matters right here, within the hotel building, without wasting a minute of their precious time. The conference hall is equipped with all the necessary presentation equipment.',
    },
    {
      id: 2,
      image: '/media/img/services/services2.jpg',
      title: 'Restaurants',
      undertitle: '',
      description: 'In the morning, you will be greeted with hot coffee and breakfast, tasty dishes for lunch, and in the evening – luxurious drinks, exquisite dishes from world cuisines, and a magnificent sea view. Traditional and unparalleled dishes prepared by chefs will satisfy the taste of any gourmet.',
    },
    {
      id: 3,
      image: '/media/img/services/services3.jpg',
      title: 'Lobby Bar',
      undertitle: '',
      description: 'The bar combines a pleasant atmosphere, soothing music, and comfortable lighting. Your conversations will be accompanied by the finest drinks, original cocktails, and light snacks.',
    },
    {
      id: 4,
      image: '/media/img/services/services4.jpg',
      title: 'SPA Center',
      undertitle: '',
      description: 'Relaxing treatments and massages.',
    },
    {
      id: 5,
      image: '/media/img/services/services5.jpg',
      title: 'Other Services',
      undertitle: '',
      description: 'Outdoor pool, Indoor pool with artificial wave, Sauna, Turkish Hammam, Massage, Dentistry, Pharmacy, Gym, Indoor and outdoor water park, Summer and winter cafes, Shops, Cinema, Summer disco, Karaoke hall, Hairdresser, High-speed free Wi-Fi, Billiards, 4-lane Bowling, Mini golf, Tennis courts, Beach games and children\'s areas, Prayer room.',
    }
  ];

  const savedLanguage = localStorage.getItem('language') || 'tm';
  // Состояние для языка
  const langs = {
    'ru': {
      'title': "Услуги",
      'services': services_ru,
      'more_btn': "Детали",
    },
    'tm': {
      'title': "Hyzmatlar",
      'services': services_tm,
      'more_btn': "Maglumat",
    },
    'en': {
      'title': "Services",
      'services': services_en,
      'more_btn': "More",
    }
  };


  return (
    <section className="services container">
      <h1 className='rooms-title'>{langs[savedLanguage].title}</h1>
      <div className="services-container">
        {langs[savedLanguage].services.map((service) => (
          <div key={service.id} className="service-card">
            <img src={service.image} alt={service.title} className="service-image" />
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.undertitle}</p>
            {/* <a href={`/services/${service.id}`} className="service-button">Подробнее</a> */}
            <a href="#" className="service-button">{langs[savedLanguage].more_btn}</a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServicesComponent;

import React, { useEffect, useState } from 'react';
import '../styles/css/index.css';

import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';

function Rooms() {
  const savedLanguage = localStorage.getItem('language') || 'tm';

  const langs = {
    'ru': {
      'title': "Контакты",
      'hotel_name': "Отель Berkarar",
      'address': "Туркменистан, Национальная туристическая зона «Аваза»",
      'tel': "Тел",
      'faks': "Факс",
    },
    'tm': {
      'title': "Habarlaşmak",
      'hotel_name': "Otel Berkarar",
      'address': "Türkmenistan, “Awaza” milli syýahatçylyk zolagy",
      'tel': "Tel",
      'faks': "Faks",
    },
    'en': {
      'title': "Contacts",
      'hotel_name': "Hotel Berkarar",
      'address': "Turkmenistan, National Tourist Zone “Avaza”",
      'tel': "Tel",
      'faks': "Fax",
    }
  };

    return (
        <React.Fragment>
            <HeaderComponent />
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.490360794749!2d52.833793958509645!3d39.96084824302115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x402c1bd66975f08d%3A0x53e39feb11fcf911!2z0JPQvtGB0YLQuNC90LjRhtCwICLQkdC10YDQutCw0YDQsNGAIg!5e0!3m2!1sru!2s!4v1733435260959!5m2!1sru!2s"
              width="100%"
              height="500"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            /> */}
            <section className="contacts">
              <h2 className="contacts__title">{langs[savedLanguage].title}</h2>
              <div className="contacts__info">
                <h3 className="contacts__hotel-name">{langs[savedLanguage].hotel_name}</h3>
                <p className="contacts__address">{langs[savedLanguage].address}</p> 
                <div className="contacts__phone">
                  <a href="tel:+99324357654">{langs[savedLanguage].tel}: +993 243 5-76-54</a>
                  <a href="tel:+99324357635">{langs[savedLanguage].tel}: +993 243 5-76-35</a>
                  <a href="tel:+99324357630">{langs[savedLanguage].faks}: +993 243 5-76-30</a>
                </div>
                <p className="contacts__email">
                  <a href="mailto:berkarar.travel.avaza@gmail.com">berkarar.travel.avaza@gmail.com</a>
                </p>
              </div>
            </section>
            <FooterComponent />
        </React.Fragment>
    );
}

export default Rooms;

import React from 'react';

import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import SliderComponent from '../components/AboutUsPage/SliderComponent';
// const token = localStorage.getItem('accessToken');
// const role = localStorage.getItem('asd');
// {token && role === "client" ? (
//     <BookingComponent />
// ) : (null)}
const AboutUs = () => {

  const savedLanguage = localStorage.getItem('language') || 'tm';

  const langs = {
    'ru': {
      'title': "Об отеле",
      'text': `
Наш отель расположен в Национальной туристической зоне «Аваза».
Отель класса люкс, отличающийся особым изяществом, расположенный на первой береговой линии, состоящий  из пяти разновысотных блоков , создающий вместе очертания развевающегося на ветру Государственного флага Туркменистана.


В общей сложности  отель «Беркарар» рассчитана на прием 387 человек.  Номерной фонд отеля состоит из апартаментов категорий «VIP ULTRA LUXE», «VIP DE LUXE», «VIP LUXE четырехместный», «VIP LUXE двухместный», «Апартаменты двухместные»,«LUXE», «Двухместный» и «Одноместный». Вне зависимости от класса, номера отвечают мировым стандартам курортного сервиса, располагают отдельными гостиными, спальными, ванными комнатами и отличаются друг от друга лишь числом функциональных помещений и размерами общей площади.


Дизайн интерьеров соответствует последним модным тенденциям и продуман до мельчайших деталей . В номерах имеется телевидение, кондиционер, действует внутренняя телефонная связь и высокоскоростной  Wi-Fi . 
В нашем отеле вы найдете отличные рестораны, конференц залы и банкетные залы для проведения деловых и неофициальных мероприятий. Каждый посетитель нашего отеля может воспользоваться услугами СПА-центра, фитнес зала и бассейнами летнего и зимнего типа.


Hаша главная цель – сделать все возможное, чтобы гости чувствовали себя комфортно. 

Внимание и забота о наших гостях – наш приоритет.
Спасибо, что выбираете нас!
      `
    },
    'tm': {
      'title': "Biz hakda",
      'text': `
Biziň myhmanhanamyz «Awaza» Milli syýahatçylyk zolagynda ýerleşýär.
Ýokary derejeli myhmanhana, aýratyn nepisligi bilen tapawutlanýan, birinji
hatar kenar ýakasynda ýerleşen we dürli beýiklikdäki bäş blogdan ybarat
bolup, şol birlikde Türkmenistanyň Döwlet Baýdagynyň parlak keşbini emele
getirýär.

Jemi 387 adam kabul etmäge niýetlenen «Berkarar» myhmanhanasynyň
otag gaznasy dürli kategoriýaly otaglardan ybarat: «VIP ULTRA LUXE», «VIP
DE LUXE», «VIP LUXE dört orunlyk», «VIP LUXE iki orunlyk»,
«APPARTAMENT Iki orunlyk», «LUXE», «Iki orunlyk» we «Bir orunlyk».
Derejelerine garamazdan özbaşdaklygy we oňaýlygy bilen otaglar dünýä
ülňülerine laýyklykda dynç alyş hyzmatlaryny hödürleýär, aýratyn myhman
otaglary, ýatylýan otaglary, hammam otaglary bilen üpjün edilendir we diňe
funksional otaglaryň sany we umumy meýdanyň ululygy boýunça biri-
birinden tapawutlanýar.

Içerki dizaýn iň soňky moda ugruna laýyklykda döredilip, iň ownuk jikme-
jikliklere çenli oýlanylyp taýýarlanypdyr. Otaglarda telewizor, kondisioner,
içerki telefon aragatnaşygy we ýokary tizlikli Wi-Fi bar.
Biziň myhmanhanamyzda işewür we resmi däl çäreleri geçirmek üçin ajaýyp
restoranlar, konferens zallary we banket zallary bar. Myhmanhanamyzda
her bir myhman SPA merkeziniň, fitness zallarynyň, tomus we gyş görnüşli
suwa düşülýän howuzlaryň hyzmatlaryndan peýdalanyp biler.

Biziň esasy maksadymyz - myhmanlaryň rahatlygyny üpjün etmek üçin ähli
mümkinçiligi etmekdir.


Myhmanlarymyza ünsi we aladany bermek - biziň esasy möhüm
wezipämizdir.
Bizi saýlandygyňyz üçin sag boluň!
      `
    },
    'en': {
      'title': "About us",
      'text': `
Our hotel is located in the National Tourist Zone "Avaza."
A luxurious hotel distinguished by its unique elegance, situated on the first coastline. The complex comprises five multi-level blocks forming the outline of the waving State Flag of Turkmenistan.


The "Berkarar" Hotel has a total capacity to accommodate 387 guests. Its room inventory includes apartments of various categories: "VIP ULTRA LUXE," "VIP DE LUXE," "VIP LUXE Four-Bedroom," "VIP LUXE Two-Bedroom," "Two-Bedroom Apartments," "LUXE," "Double Room," and "Single Room." Regardless of the class, all rooms meet international resort service standards, offering separate living rooms, bedrooms, and bathrooms, differing only in the number of functional areas and total space.


The interior design reflects the latest trends and is meticulously detailed. Rooms are equipped with television, air conditioning, internal telephone connections, and high-speed Wi-Fi.
Our hotel offers excellent restaurants, conference rooms, and banquet halls for hosting both business and informal events. Guests can enjoy the services of a SPA center, fitness room, and both summer and winter swimming pools.


Our primary goal is to do everything possible to ensure guests feel comfortable.

Attention and care for our guests are our top priorities.
Thank you for choosing us!
      `
    }
  };


  return (
    <React.Fragment>
        <HeaderComponent />
        <SliderComponent />
            <section className="about-section">
            <div className="container">
                <h2 className="about-title">{langs[savedLanguage].title}</h2>
                <p className="about-text">
                  {langs[savedLanguage].text}
                </p>
                {/* <div className="about-section__gallery">
                  <img src="/media/img/services/services3.jpg" alt="Service 1" />
                  <img src="/media/img/services/services3.jpg" alt="Service 2" />
                  <img src="/media/img/services/services3.jpg" alt="Service 3" />
                  <img src="/media/img/services/services3.jpg" alt="Service 4" />
                  <img src="/media/img/services/services3.jpg" alt="Service 5" />
                  <img src="/media/img/services/services3.jpg" alt="Service 6" />
                  <img src="/media/img/services/services3.jpg" alt="Service 7" />
                </div> */}
            </div>
            </section>
        <FooterComponent />
    </React.Fragment>
  );
};

export default AboutUs;

import React from 'react';
import '../styles/css/index.css';

import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';

import SliderComponent from '../components/HomePage/SliderComponent';
import SliderRoomsComponent from '../components/HomePage/SliderRoomsComponent';
import SliderReviewsComponent from '../components/HomePage/SliderReviewsComponent';

function Home() {

    const token = localStorage.getItem('accessToken');
    const role = localStorage.getItem('asd');


    return (
        <React.Fragment>
            <HeaderComponent />
            <SliderComponent />
            <SliderRoomsComponent />
            <SliderReviewsComponent />
            <FooterComponent />
        </React.Fragment>
    );
}

export default Home;

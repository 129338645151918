import { useNavigate } from 'react-router-dom';
import { apiUrl } from '../../config';
import { useState } from 'react';
import useAuthRedirect from '../../utils/modules/useAuthRedirect'
import { useLanguage } from '../../LanguageContext';

const ClientRegisterComponent = () => {  
  const navigate = useNavigate();
  const [isRegister, setIsLogin] = useState(false); // Состояние для переключения между логином и регистрацией
  const [loginData, setLoginData] = useState({
    'email': '',
    'password': '',
    'confirm_password': ''
  });
  const [message, setMessage] = useState('');

  // Проверка авторизации при загрузке компонента
  useAuthRedirect('client', '/', '/authorization');

  const handleToggle = () => {
    setIsLogin(!isRegister); // Переключение между логином и регистрацией
  };

  const client_register = async () => {
  
    // Проверка на наличие данных и корректность пароля
    if (!loginData.password || loginData.password.length < 5) {
      setMessage('Пароль должен иметь как минимум 5 символов');
      return; // Прерываем выполнение функции, если пароль некорректен
    }
  
    if (loginData.password !== loginData.confirm_password) {
      setMessage('Пароли не совпадают');
      return;
    }
    setMessage('Пожалуйста подождите...');
  
    try {
      // Логика для отправки данных на сервер
      const response = await fetch(`${apiUrl}/api/client-register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData), // Убедитесь, что loginData правильно формируется
      });
  
      const result = await response.json();
      if (result.request.status === 0) {
        handleToggle()
        alert(result.request.message)
        setMessage('');
      } else {
        setMessage(result.request.message); // Отображение сообщения об ошибке
      }
    } catch (error) {
      console.error('Ошибка при обработке запроса:', error);
      setMessage('Ошибка при обработке запроса.');
    }
  };
  const client_login = async () => {
  
    // Проверка на наличие данных и корректность пароля
    if (!loginData.email.length || !loginData.password.length) {
      setMessage('Вы не ввели данные');
      return; // Прерываем выполнение функции, если пароль некорректен
    }

    setMessage('Пожалуйста подождите...');
  
    try {
      // Логика для отправки данных на сервер
      const response = await fetch(`${apiUrl}/api/client-login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData), // Убедитесь, что loginData правильно формируется
      });
  
      const result = await response.json();
      if (result.request.status === 0) {
        localStorage.setItem('accessToken', result.request.token);
        localStorage.setItem('asd', result.request.asd);
        navigate('/');
        alert("Успешный вход. Смело бронируйте номер")
      } else {
        setMessage(result.request.message); // Отображение сообщения об ошибке
      }
    } catch (error) {
      console.error('Ошибка при обработке запроса:', error);
      setMessage('Ошибка при обработке запроса.');
    }
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (!isRegister) {
      setLoginData((prev) => {
        const updatedData = { ...prev, [name]: value };
    
        // Проверка на минимальную длину пароля
        if (name === 'password' && value.length < 5) {
          setMessage('Пароль должен иметь как минимум 5 символов');
        } else if (name === 'confirm_password' || name === 'password') {
          // Проверка на совпадение паролей
          if (updatedData.password !== updatedData.confirm_password) {
            setMessage('Пароли не совпадают');
          } else {
            setMessage(''); // Сброс ошибки, если всё совпадает
          }
        }
    
        return updatedData;
      });
    } else {
      setLoginData((prev) => ({ ...prev, [name]: value }));
    }

  };
  const { language } = useLanguage();

  const langs = {
    'ru': {
      'title': "Регистрация",
      'email': "Электронная почта",
      'email_placeholder': "Введите действующую эл. почту",
      'password': "Пароль",
      'password_placeholder': "Придумайте пароль",
      'passwordc': "Подтвердите пароль",
      'passwordc_placeholder': "Введите пароль еще раз",
      'login_btn': "Войти",
      'register_btn': "Регистрация",
      'create_acc': "Нет аккаунта", 
    },
    'tm': {
      'title': "Hasaba al",
      'email': "Elektron poçta",
      'email_placeholder': "Işjeň elektron poçtaňyzy giriziň",
      'password': "Parol",
      'password_placeholder': "Parol oýlap tapyň",
      'passwordc': "Paroly tassyklanyň",
      'passwordc_placeholder': "Paroly ýene giriziň",
      'login_btn': "Girmek",
      'register_btn': "Hasaba al",
      'create_acc': "Hasabyňyz ýokmy", 
    },
    'en': {
      'title': "Registration",
      'email': "Email",
      'email_placeholder': "Enter a valid email address",
      'password': "Password",
      'password_placeholder': "Create a password",
      'passwordc': "Confirm Password",
      'passwordc_placeholder': "Enter the password again",
      'login_btn': "Login",
      'register_btn': "Register",
      'create_acc': "Don't have an account", 
    }
  };
  
  return (
    <div className="background-floor">
      <form className="form-simple container">
        {isRegister ? (
          <>
          <h4 className='form-simple__title'>{langs[language].title}</h4>
          {/* <!-- Электронная почта --> */}
          <div className="form-group">
            <label htmlFor="email">{langs[language].email}:</label>
            <input type="email" placeholder={langs[language].email_placeholder} value={loginData.email} id="email" name="email" required onChange={handleInputChange} />
          </div>

          <div className="form-group">
            <label htmlFor="password">{langs[language].password}</label>
            <input type="password" placeholder={langs[language].password_placeholder} value={loginData.password} id="password" name="password" required onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label htmlFor="confirm_password">{langs[language].passwordc}</label>
            <input type="password" placeholder={langs[language].passwordc_placeholder} value={loginData.confirm_password} id="confirm_password" name="confirm_password" required onChange={handleInputChange} />
          </div>

          <h3 className='error-message'>{message && message}</h3>
          {/* <!-- Кнопка для подтверждения бронирования --> */}
          <button type="button" className="button-booking" onClick={() => client_register()}>
            {langs[language].register_btn}
          </button>
          <p className='toggleClientForm'>{langs[language].create_acc}? <span onClick={handleToggle}>{langs[language].login_btn}</span></p>
          </>
        ) : (
          <>
          <h4 className='form-simple__title'>Вход</h4>
          {/* <!-- Электронная почта --> */}
          <div className="form-group">
            <label htmlFor="email">{langs[language].email}:</label>
            <input type="email" placeholder={langs[language].email_placeholder} value={loginData.email} id="email" name="email" required onChange={handleInputChange} />
          </div>

          <div className="form-group">
            <label htmlFor="password">{langs[language].password}</label>
            <input type="password" placeholder={langs[language].password} value={loginData.password} id="password" name="password" required onChange={handleInputChange} />
          </div>

          <h3 className='error-message'>{message && message}</h3>
          {/* <!-- Кнопка для подтверждения бронирования --> */}
          <button type="button" className="button-booking" onClick={() => client_login()}>
            {langs[language].login_btn}
          </button>
          <p className='toggleClientForm'>Нет аккаунта? <span onClick={handleToggle}>{langs[language].register_btn}</span></p>
          </>
        )}

      </form>
    </div>
  )
}

export default ClientRegisterComponent;
import React, { useEffect, useState } from 'react';

import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import RoomCard from '../components/RoomsPage/RoomCard';

import { fetchData } from '../utils/api'
import { apiUrl } from '../config'
import { useLanguage } from '../LanguageContext';

function Rooms() {
    const { language } = useLanguage();
    const [rooms, setRooms] = useState([]);
    const [bookingPrice, setBookingPrice] = useState(0);
    useEffect(() => {
      const fetchRooms = async () => {
        const response = await fetchData(`${apiUrl}/api/${language}/get-rooms`, "GET")
        if (response.request && response.request.status === 0) {
          setRooms(response.request.data.rooms)
          setBookingPrice(response.request.data.booking_price)
        }
      }
      fetchRooms()
    }, [language]);

      // Чтение языка из localStorage, если он сохранён
  const savedLanguage = localStorage.getItem('language') || 'tm';
  // Состояние для языка
  const langs = {
    'ru': {
      'title': "Апартаменты",
    },
    'tm': {
      'title': "Otaglar",
    },
    'en': {
      'title': "Apartments",
    }
  };


  return (
      <React.Fragment>
          <HeaderComponent />
          <h1 className='rooms-title'>{langs[savedLanguage].title}</h1>

          <div className="rooms-page-list container">
              {rooms.map((room, index) => (
                  <RoomCard key={index} typePage={'view'} room={room} bookingPrice={bookingPrice} />
              ))}
          </div>
          <FooterComponent />
      </React.Fragment>
  );
}

export default Rooms;

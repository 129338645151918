import { apiUrl } from '../config';
import React, { useState } from 'react';
import { fetchData } from '../utils/api';
import { useLanguage } from '../LanguageContext';
import { renderToStaticMarkup } from 'react-dom/server';

const BookingFormComponent = ({ room, checkIn, checkOut, onClose }) => {
  const [guests, setGuests] = useState([{ full_name: '', passport_serial_number: '' }]);
  const [bookingData, setBookingData] = useState({...room, phone_number: ''});
  const [isDisabled, setIsDisabled] = useState(false);

  const [message, setMessage] = useState(null);
  const { language } = useLanguage();

  const payAtBank = async () => {
    setIsDisabled(true)
    const requestData = {
      'check_in': checkIn,
      'check_out': checkOut,
      'room_id': bookingData.id,
      'phone_number': bookingData.phone_number,
      'guests': guests
    }
    if (requestData.room_id) {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await fetchData(`${apiUrl}/api/${language}/payment`, "POST", token, {body: requestData})
        if (response.request.status === 0) {
          window.location.href = response.request.data.formUrl;
        } else {
          setMessage(response.request.message)
          // Включаем кнопку снова через 3 секунды
          setTimeout(() => setIsDisabled(false), 3000);
        }
      } catch (error) {
        console.error("Ошибка при перенаправлении на банк: ", error)
        // console.error("Ошибка при перенаправлении на банк.")
      }
    }
  }

  // Обработчик изменений для гостя
  const handleGuestChange = (index, field, value) => {
    const updatedGuests = [...guests];
    updatedGuests[index][field] = value;
    setGuests(updatedGuests);
  };
  
  // Добавление гостя
  const addGuest = () => {
    if (guests.length < room.capacity) {
      setGuests([...guests, { full_name: '', passport_serial_number: '' }]);
    }
  };

  // Удаление гостя
  const removeGuest = (index) => {
    if (guests.length > 1) {
      setGuests(guests.filter((_, i) => i !== index));
    }
  };

  // Обработчик изменений для других полей
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBookingData({ ...bookingData, [name]: value });
  };

  const langs = {
    'ru': {
      'full_name': "Имя и фамилия гостя",
      'phone_number': "Номер телефона",
      'passport_serial_number': "Серийный номер паспорта гостя",
      'cancellation_terms': "Ознакомьтесь с условиями отмены брони",
      'room_service': "Услуга номера (1 сутки):",
      'check_in': "Дата заезда:",
      'check_out': "Дата выезда:",
      'room_type': "Тип номера:",
      'capacity': "Количество гостей которые могут разместиться в номере:",
      'rooms_count': "Количество комнат в номере:",
      'payment_info': "Оплата производится онлайн, а также оплачиваются первые сутки проживания. За следующие сутки нужно произвести оплату на ресепшн. В случае отмены брони за 72 часа, стоимость за проживания первых суток возвращается.",
      'smoking_prohibited': "Курение запрещено",
      'no_parties': "Нельзя проводить вечеринки/мероприятия",
      'confirm_booking': "Подтвердить бронирование (переход на станицу банка)",
      'add_guest': "Добавить гостя",
      'remove_guest': "Удалить гостя"
    },
    'tm': {
      'full_name': "Myhman ady we familiýasy",
      'phone_number': "Telefon belgisi",
      'passport_serial_number': "Myhman pasportynyň seriýa belgisi",
      'cancellation_terms': "Rezervasiýa ýatyrmak şertleri bilen tanyş boluň",
      'room_service': "Oteliň hyzmaty (1 gün):",
      'check_in': "Girizmek senesi:",
      'check_out': "Çykan senesi:",
      'room_type': "Otagyň görnüşi:",
      'capacity': "Otagda ýerleşip biljek myhmanlaryň sany:",
      'rooms_count': "Otagyň sany:",
      'payment_info': "Ödeniş online ýagdaýda amala aşyrylýar, şondan soňky günler resepsioniň arkaly tölenmeli. Rezervasiýany 72 sagadyň içinde ýatyranyňyzda, ilkinji günleriň tölegi gaýtarylyp berilýär.",
      'smoking_prohibited': "Çilim çekmek gadagandyr",
      'no_parties': "Ýygnaklary/çäräleri geçirmäge rugsat berilmeýär",
      'confirm_booking': "Rezervasiýany tassykla (bank saýtyna geçiş)",
      'add_guest': "Myhman goş",
      'remove_guest': "Myhmany pozmak"
    },
    'en': {
      'full_name': "Guest's full name",
      'phone_number': "Phone number",
      'passport_serial_number': "Guest's passport serial number",
      'cancellation_terms': "Review cancellation policy",
      'room_service': "Room service (1 day):",
      'check_in': "Check-in date:",
      'check_out': "Check-out date:",
      'room_type': "Room type:",
      'capacity': "Number of guests the room can accommodate:",
      'rooms_count': "Number of rooms in the unit:",
      'payment_info': "Payment is made online, and the first night's stay is paid. For subsequent nights, payment is to be made at the reception. If the reservation is canceled 72 hours in advance, the first night's stay is refunded.",
      'smoking_prohibited': "Smoking is prohibited",
      'no_parties': "Parties/events are not allowed",
      'confirm_booking': "Confirm booking (redirect to bank page)",
      'add_guest': "Add Guest",
      'remove_guest': "Remove Guest"
    }
  };
  

  return (
    <div className="background-floor" id="bookingForm">
      <form className="booking-form container">
        <div className="pos-rel">
          <span className="closer-icon" onClick={() => onClose()}>X</span>
        </div>
        {/* Номер телефона */}
        <div className="form-group">
          <label htmlFor="phone_number">{langs[language].phone_number}:</label>
          <input type="tel" placeholder={langs[language].phone_number} value={bookingData.phone_number} id="phone_number" name="phone_number" required onChange={handleInputChange} />
        </div>
        {/* Динамические поля для гостей */}
        <div className='booking-form__buttons'>
          {/* Кнопка добавления гостя */}
          {guests.length < room.capacity && (
            <button type="button" className='button-booking button-booking-small' onClick={addGuest}>
              {langs[language].add_guest || 'Add Guest'}
            </button>
          )}
        </div>
        <div className='booking-form__guests'>
          {guests.map((guest, index) => (
            <div key={index} className='booking-form__guests___guest'>
              {/* <!-- Имя и фамилия --> */}
              <div className="form-group">
                <label htmlFor="full_name">{langs[language].full_name}:</label>
                <input
                  type="text"
                  placeholder={langs[language].full_name}
                  value={guest.full_name}
                  id={`full_name_${index}`}
                  name={`full_name_${index}`}
                  required
                  onChange={(e) => handleGuestChange(index, 'full_name', e.target.value)} />
              </div>
              {/* Серийный номер паспорта */}
              <div className="form-group">
                <label htmlFor="passport_serial_number">{langs[language].passport_serial_number}:</label>
                <input
                  type="text"
                  maxLength={15}
                  placeholder='I-AS 000000'
                  value={guest.passport_serial_number}
                  id={`passport_serial_number_${index}`}
                  name={`passport_serial_number_${index}`}
                  required
                  onChange={(e) => handleGuestChange(index, 'passport_serial_number', e.target.value)} />
              </div>
              {guests.length > 1 && (
                <button type="button" className='button-booking button-booking-small' onClick={() => removeGuest(index)}>
                  {langs[language].remove_guest || 'Remove Guest'}
                </button>
              )}
            </div>
          ))}
        </div>

        <ul className="booking-form__list-data">
          <li className="booking-form__list-data__item">
            <a className='booking-form__link-cancel' href='/cancellation-terms' title={langs[language].cancellation_terms}>
              {langs[language].cancellation_terms}
            </a>
          </li>
          <li className="booking-form__list-data__item">
            {langs[language].room_service} <span className="fw-b">{bookingData.price} TMT</span>
          </li>
          <li className="booking-form__list-data__item">
            {langs[language].check_in} <span className="fw-b">{checkIn}</span>
          </li>
          <li className="booking-form__list-data__item">
            {langs[language].check_out} <span className="fw-b">{checkOut}</span>
          </li>
          <li className="booking-form__list-data__item">
            {langs[language].room_type} <span className="fw-b">{room.translations.room_type}</span>
          </li>
          <li className="booking-form__list-data__item">
            {langs[language].capacity} <span className="fw-b">{room.capacity}</span>
          </li>
          <li className="booking-form__list-data__item">
            {langs[language].rooms_count} <span className="fw-b">{room.rooms_count}</span>
          </li>
          <li className="booking-form__list-data__item">
            {langs[language].payment_info}
          </li>
          <li className="booking-form__list-data__item">{langs[language].smoking_prohibited}</li>
          <li className="booking-form__list-data__item">{langs[language].no_parties}</li>
        </ul>

        <h3 className='error-message'>{message && message}</h3>

        {/* Кнопка для подтверждения бронирования */}
        <button type="button" className="button-booking" disabled={isDisabled} onClick={() => payAtBank(bookingData)}>
          {langs[language].confirm_booking}
        </button>
      </form>
    </div>
  )
}

export default BookingFormComponent;
import React, { useEffect, useState } from 'react';
import '../../styles/css/slider.css';

const slides = [
  { id: 1, image: './media/img/aboutus/aboutus-1.jpg' },
  { id: 2, image: './media/img/aboutus/aboutus-2.jpg' },
  { id: 3, image: './media/img/aboutus/aboutus-3.jpg' },
  { id: 4, image: './media/img/aboutus/aboutus-4.jpg' },
];

const SliderComponent = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000); // Slide changes every 4 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="slider">
      {slides.map((slide, index) => (
        <div
          key={slide.id}
          className={`slide ${index === currentSlide ? 'active' : ''}`}
          style={{ backgroundImage: `url(${slide.image})` }}
        >
        </div>
      ))}
    </div>
  )
}

export default SliderComponent;

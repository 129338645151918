import React, { useEffect, useState } from 'react';
import '../styles/css/index.css';

import { fetchData } from '../utils/api'
import { apiUrl } from '../config'
import { useLocation, useSearchParams } from 'react-router-dom';
import FooterComponent from '../components/FooterComponent';
import HeaderComponent from '../components/HeaderComponent';
import BookingFormComponent from '../components/BookingFormComponent';
import useAuthRedirect from '../utils/modules/useAuthRedirect';
import { useParams } from "react-router-dom";
import { useLanguage } from '../LanguageContext';
import Protected from '../components/system/Protected';

function RoomDetail() {
    const { id, typePage } = useParams();
    const [searchParams] = useSearchParams();
    const checkIn = searchParams.get("checkIn");
    const checkOut = searchParams.get("checkOut");


    const [room, setRoom] = useState(null);
    const [imageRoom, setImageRoom] = useState(null)  
    const [bookingPrice, setBookingPrice] = useState(0);
    const { language } = useLanguage();
    const [bookingCheckVisible, setBookingCheckVisible] = useState(false);
    const [mayPay, setMayPay] = useState(0);

    const getAccBookingCount = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await fetchData(`${apiUrl}/api/check-count-bookings`, "GET", token)
        setMayPay(response.request.data)
      } catch (error) {
        // console.error("Ошибка при перенаправлении на банк.")
      }
    }

    useEffect(() => {
      const fetchRooms = async () => {
        const response = await fetchData(`${apiUrl}/api/${language}/get-room/${id}`, "GET")
        if (response.request && response.request.status === 0) {
          const fetchedRoom = response.request.data.room;
          setRoom(response.request.data.room)
          setBookingPrice(response.request.data.booking_price)
          // Устанавливаем первую картинку, если она существует
          if (fetchedRoom.images && fetchedRoom.images.length > 0) {
            setImageRoom(fetchedRoom.images[0]);
          }
        }
      }
      fetchRooms()
      if (typePage === "book") {
        const role = localStorage.getItem('asd');
        if (role) {
          getAccBookingCount()
        }
      }
    }, [language, id, typePage]);
    // Отображение формы для перехода на страницу банка
    const handleSubmitToBookingForm = () => {
      // Проверка авторизации при загрузке компонента
      setBookingCheckVisible(true); // Show the booking form
    }

    const closeBookingForm = () => {
      setBookingCheckVisible(false)
    };

    const savedLanguage = localStorage.getItem('language') || 'tm';
    // Состояние для языка
    const langs = {
      'ru': {
        'additional_info': "Дополнительная информация:",
        'searchResultTitle': "Результаты поиска",
        'book_price': "Цена номера в сутки",
        'room_type': "Тип апартамента",
        'count_guests': "Кол. гостей",
        'description': "Описание",
        'amenities': "Оснащение",
        'max_rooms_title': "Количество комнат в номере",
        'rooms_title': "На данный момент доступно номеров",
        'no_rooms_available': "Свободных номеров нет.",
        'button_book': "Забронировать",
        'message': "У вас уже имеются бронирования",
      },
      'tm': {
        'additional_info': "Goşmaça maglumat:",
        'searchResultTitle': "Gözleg netijeleri",
        'book_price': "Bir gije gündiz üçin otagyň bahasy",
        'room_type': "Otagyň görnüşi",
        'count_guests': "Myhmanlaryň sany",
        'description': "Beýany",
        'amenities': "Otag enjamlary",
        'max_rooms_title': "Otag sany",
        'rooms_title': "Häzirki wagtda elýeterli otaglar",
        'no_rooms_available': "Elýeterli otag ýok.",
        'button_book': "Rezervirlemek",
        'message': "Siziň eýýäm bronlamalaryňyz bar",
      },
      'en': {
        'additional_info': "Additional information:",
        'searchResultTitle': "Results",
        'book_price': "Price per night",
        'room_type': "Apartment Type",
        'count_guests': "Guests",
        'description': "Description",
        'amenities': "Amenities",
        'max_rooms_title': "Rooms in the Apartment",
        'rooms_title': "Currently Available Rooms",
        'no_rooms_available': "No available rooms.",
        'button_book': "Book now",
        'message': "You already have bookings",
      }
    };
    if (!room) {
      return <div>Загрузка...</div>; // Показать пока данные не загружены
    }
    return (
      <React.Fragment>
        <HeaderComponent />
        <div className="container container-rooms-result" id='results-block'>
        <h2 className="container-rooms-result__title">{langs[savedLanguage].title}</h2>
        <h4 className='text searching-status-booking' id='results-block-inner-status'>{!room && "Номеров не осталось"}</h4>
        {room ? (
          <div className="room-result" id='results-block-inner'>
            <h3 className="room-result__type">{room.translations.room_type}</h3>
            <div className='room-result__main-images-block'>
              <div className='room-result__main-images-block__main-image'>
                <img
                  src={`${apiUrl}/api/images/${imageRoom}`}
                  alt={room.translations.room_type}
                />
              </div>
              {room.images ? (
              <div className="room-result__main-images-block__images">
                {room.images.map((image, index) => (
                    <img
                    key={index + 1}
                    src={`${apiUrl}/api/images/${image}`}                
                    alt={room.translations.room_type + (index + 1)}
                    onMouseEnter={() => setImageRoom(image)}
                    />
                ))}
              </div>
              ) : (null)}

            </div>

            <ul className='room-result__services-room room-result__hr-bottom'>
              {/* <li className='room-result__services-room__item'><span>Услуга бронирования (сумма)</span>{bookingPrice} TMT</li> */}
              <li className='room-result__services-room__item'><span>{langs[savedLanguage].book_price}</span>{room.price} TMT</li>
              <li className='room-result__services-room__item'><span>{langs[savedLanguage].room_type}</span>{room.translations.room_type}</li>
              <li className='room-result__services-room__item'><span>{langs[savedLanguage].count_guests}</span>{room.capacity}</li>
            </ul>
            <div className='room-result__description-room room-result__hr-bottom'>
              <div className='room-result__description-room__block'>
                <h4 className='room-result__description-room__title'>{langs[savedLanguage].description}</h4>
                <pre className='room-result__description-room__text'>
                  {room.translations.description}
                </pre>
              </div>
              {room.translations.additional_info &&
              <ul className='room-result__additional-services'>
                <li className='room-result__additional-services__item red-text'>{langs[savedLanguage].additional_info}</li>
                <pre className='red-text'>{room.translations.additional_info}</pre>
              </ul>
              }
              {room.amenities &&
              <ul className='room-result__additional-services'>
                <li className='room-result__additional-services__item room-result__additional-services__title'>{langs[savedLanguage].amenities}</li>
                {room.amenities.map((amenite, index) => (
                  <li key={index} className='room-result__additional-services__item'>{amenite}</li>
                ))}
              </ul>
              }
            </div>


            <p className="room-result__floor">
              {langs[savedLanguage].max_rooms_title}: <strong>{room.rooms_count}</strong>
            </p>
            {typePage === 'book' && (
              mayPay < 2 && room ? (
                <button className="button-booking pulse-button button-zj" onClick={() => handleSubmitToBookingForm(room)}>
                    {langs[language].button_book}
                </button>
              ) : (
                <p className='red-text'>{langs[language].message}</p>
              )
            )}
          </div>
        ) : null}
      </div>
      { bookingCheckVisible && (
        <Protected
          element={
            <BookingFormComponent room={room} checkIn={checkIn} checkOut={checkOut} onClose={closeBookingForm} />
          } requiredRoles={['client']}
        />
      )}
      <FooterComponent />
    </React.Fragment>
    );
}

export default RoomDetail;

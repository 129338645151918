// RoomSlider.jsx
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules'; // Импорт модулей
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const SliderReviewsComponent = () => {

  const reviews = [
    {
      'id': 1,
      'client': 'Джерен', 
      'text': 'Волшебная атмосфера, мягкий свет, и чарующий аромат эфирных масел. Сам массаж — это нечто потрясающее! Невероятное ощущение комфорта, профессионализм на высшем уровне, и техника горячих компрессов впечатляет. Каждая процедура дарит не только эстетическое удовольствие, но и настоящее физическое и моральное восстановление. Благодарю за незабываемый опыт!'
    },
    {
      'id': 2,
      'client': 'Довлет', 
      'text': 'Потрясающий массаж! Уютная обстановка, нежный аромат масел и приятная музыка создают идеальную атмосферу для релаксации. Мастер работает профессионально, с заботой и вниманием к деталям. Горячие компрессы добавляют особое ощущение тепла и уюта. После процедуры ощущение легкости и умиротворения. Рекомендую!'
    },
    {
      'id': 3,
      'client': 'Айгуль', 
      'text': 'Непередаваемые эмоции! С первых минут окунаешься в мир спокойствия и гармонии: приглушённый свет, ароматные свечи и волшебные руки мастера. Массаж с горячими компрессами — отдельное удовольствие, настоящий ритуал. Процедуры не только для тела, но и для души. Ушла полностью обновленной. Спасибо за этот момент счастья!'
    },
    {
      'id': 4,
      'client': 'Оля', 
      'text': 'Неповторимая атмосфера релаксации! Всё идеально — от лёгкого света до ароматов масел. Сам массаж — просто чудо, профессиональный подход и удивительные техники. Горячие компрессы добавляют комфорта и расслабления. Эффект заметен сразу: тело отдохнуло, а душа наполнилась гармонией. Большое спасибо за такое удовольствие!'
    }
  ];
  

  const savedLanguage = localStorage.getItem('language') || 'tm';
  // Состояние для языка
  const langs = {
    'ru': {
      'title': "Отзывы",
    },
    'tm': {
      'title': "Täsirler",
    },
    'en': {
      'title': "Reviews",
    }
  };

  return (
    <div className="container room-slider">
      <h1>{langs[savedLanguage].title}</h1>
      <Swiper
        slidesPerView={2}    // Отображать 3 блока одновременно
        spaceBetween={100}    // Отступ между слайдами
        pagination={{ clickable: true }} // Включить пагинацию
        autoplay={{                  // Включить автопрокрутку
          delay: 3000,               // Задержка в миллисекундах (3 секунды)
          disableOnInteraction: false, // Продолжать прокрутку после взаимодействия пользователя
        }}
        modules={[Autoplay]}
        breakpoints={{        // Адаптивные настройки
          320: {              // Для экранов шириной от 320px (мобильные)
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {             // Для экранов шириной от 768px (планшеты)
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {            // Для экранов шириной от 1024px (настольные компьютеры)
            slidesPerView: 2,
            spaceBetween: 90,
          },
        }}
      >
        {reviews.map((review) => (
          <SwiperSlide key={review.id}>
            <div className="room-card">
              <p className='room-card__text'>{review.text}</p>
              <h3 className="room-name room-card__client-name">{review.client}</h3>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SliderReviewsComponent;

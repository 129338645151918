// hooks/useAuthRedirect.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from '../../config';

const useAuthRedirect = (checkRole, redirect, abortRedirect) => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const role = localStorage.getItem('asd');
    if (token) {
      axios
        .get(`${apiUrl}/api/protected`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          if (role === checkRole) {
            if (redirect !== null) {
              navigate(redirect);
            }
          } else {
            navigate(abortRedirect);
          }
        })
        .catch(() => {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('role');
        });
    } else {
      navigate(abortRedirect);
    }
  }, [checkRole, redirect, abortRedirect, navigate]);
};

export default useAuthRedirect;